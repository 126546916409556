import Vue from 'vue'

class ReactiveStorage {
  constructor(engine) {
    Object.assign(this, engine)
    this._engine = engine
    this._prefix = ''
    return Vue.observable(this)
  }

  get(key) {
    const result = this._engine.getItem(this._prefix + key)
    try {
      return JSON.parse(result)
    } catch (err) {
      return result
    }
  }

  getItem(key) {
    return this.get(key)
  }

  getJSON(key) {
    return this.get(key)
  }

  set(key, val) {
    try {
      val = JSON.stringify(val)
    } finally {
      this[this._prefix + key] = val
      this._engine.setItem(this._prefix + key, val)
    }
  }

  setItem(key, val) {
    return this.set(key, val)
  }

  remove(key) {
    delete this[this._prefix + key]
    this._engine.removeItem(this._prefix + key)
  }

  removeItem(key) {
    this.remove(key)
  }
}

const ls = new ReactiveStorage(localStorage)
const ss = new ReactiveStorage(sessionStorage)

export { ls, ss }

export function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  Vue.prototype.$ls = ls
  Vue.prototype.$ss = ss
}
