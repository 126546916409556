import { isString } from 'lodash'

export default {
  created() {
    if (this.$options.events) {
      Object.keys(this.$options.events).forEach(key => {
        let handler = this.$options.events[key]
        if (isString(handler)) {
          handler = this[handler]
        }
        this[key + '::handler'] = handler.bind(this)
        this.$root.$on('global::' + key, this[key + '::handler'])
      })
    }
  },
  beforeDestroy() {
    if (this.$options.events) {
      Object.keys(this.$options.events).forEach(key => {
        this.$root.$off('global::' + key, this[key + '::handler'])
      })
    }
  },
  methods: {
    $broadcast(evt, payload) {
      this.$root.$emit('global::' + evt, payload)
    }
  }
}
