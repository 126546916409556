<template>
  <div :class="{bordered}" :style="style" class="avatar">
    <img :alt="alt" :src="image" @error="onError" draggable="false">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  .avatar {
    display: inline-block;
    background-color: #f2f2f2;
    vertical-align: middle;
    border-radius: 18%;
    overflow: hidden;

    &.bordered {
      border: 1px solid $hr-border-color;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>

<script>
import { anonymousUserPic } from '@/config'

export default {
  name: 'avatar',
  methods: {
    onError() {
      this.$el.querySelector('img').src = this.fallback
    }
  },
  props: {
    src: String,
    plain: Boolean,
    size: String,
    bordered: Boolean,
    round: Boolean,
    alt: String,
    fallback: {
      type: String,
      default: anonymousUserPic
    }
  },
  computed: {
    style() {
      const style = {}
      if (this.size) {
        style.width = this.size
        style.height = this.size
      }
      if (this.round) {
        style.borderRadius = '50%'
      }
      return style
    },
    image() {
      const src = this.src
      if (!src) {
        return this.fallback
      }
      if (this.plain || /unsplash/.test(src)) {
        return src
      }
      return this.$img(src)
    }
  }
}
</script>
