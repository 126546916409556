<template>
  <div class="book-icon" :class="{muted}">
    <img src="../assets/images/icons/moments.png" alt="微信书" v-if="colorful && value === 'wxbook'">
    <img src="../assets/images/icons/moments.svg" alt="微信书" v-else-if="value === 'wxbook'">

    <img src="../assets/images/icons/weibo.png" alt="微博书" v-else-if="colorful && value === 'wbbook'">
    <img src="../assets/images/icons/weibo.svg" alt="微博书" v-else-if="value === 'wbbook'">

    <img src="../assets/images/icons/diarybook.svg" alt="日记书" v-else-if="value === 'diarybook'">

    <img src="../assets/images/icons/qzone.png" alt="说说书" v-else-if="colorful && value === 'shuoshuo_book'">
    <img src="../assets/images/icons/qzone.svg" alt="说说书" v-else-if="value === 'shuoshuo_book'">

    <img src="../assets/images/icons/qbbbook.png" alt="亲宝宝书" v-else-if="colorful && value === 'qbbbook'">
    <img src="../assets/images/icons/qbbbook.svg" alt="亲宝宝书" v-else-if="value === 'qbbbook'">

    <img src="../assets/images/icons/bbsbook.svg" alt="宝宝树小时光" v-else-if="value === 'bbsbook'">

    <img src="../assets/images/icons/album.svg" alt="照片书" v-else-if="value.startsWith('album')">

    <img src="../assets/images/icons/blogbook.svg" alt="长文集" v-else-if="value === 'blogbook'">

    <fa fw icon="calendar-alt" v-else-if="/calendar/.test(value)"/>
    <fa fw icon="book-spells" v-else-if="value === 'balbum'"/>
    <fa fw icon="book" v-else-if="value === 'calbum'"/>
    <fa fw icon="gift-card" fad v-else-if="value === 'xsalbum-package'"/>

    <fa fw icon="book-alt" v-else/>
  </div>
</template>

<script>
export default {
  name: 'bookIcon',
  props: {
    value: {
      default: '',
      required: true
    },
    muted: Boolean,
    colorful: Boolean
  }
}
</script>

<style scoped lang="scss">
  .book-icon {
    display: inline-block;
    height: 1em;
    vertical-align: middle;
    margin-top: -.2em;

    &.muted {
      img {
        filter: brightness(0.4) grayscale(1);
      }

      i {
        color: $body-color;
      }
    }

    img {
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      display: block;
      width: 1.25em;
      object-fit: contain;
    }

    i {
      display: block;
      color: $primary;
    }
  }
</style>
