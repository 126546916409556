import Vue from 'vue'

Vue.directive('webp', {
  bind: init,
  update: init
})

function init(el, {value}) {
  if (!el.src || /!webp/.test(el.src)) {
    return
  }
  if (!window.canUseWebp) {
    return
  }
  const [src, size = ''] = el.src.split('!')
  el.src = src + '!webp' + (value || size)
}
