<template>
  <div :class="coverClass" class="cover">
    <div class="cover-inner" v-if="height">
      <square :height="height" :src="coverUrl" radius="0" :plain="coverClass.includes('plain')">
        <slot/>
      </square>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'cover',
  data() {
    return {
      status: 0,
      height: ''
    }
  },
  props: {
    book: Object,
    src: String,
    fill: [Boolean, String],
    plain: Boolean,
    size: [Number, String]
  },
  computed: {
    coverClass() {
      const classes = []
      classes.push(this.book.tid || this.book.bookType || '')
      const tid = this.book.tid
      if (!['xsalbum-package', 'calendar-metal', 'frame-moment'].includes(tid) && !this.plain) {
        classes.push('shadowed')
      }
      if (['xsalbum-package'].includes(tid) || this.plain) {
        classes.push('plain')
      }
      if (this.fill) {
        classes.push('fill')
        if (parseInt(this.height) > 130) {
          classes.push('fill-start')
        }
      }
      return classes
    },
    coverUrl() {
      if (this.src) {
        return this.src
      }
      const cover = get(this.book, 'cover')
      if (cover) {
        if (this.book.tid === 'frame-moment') {
          cover.pic = ''
        }
        return this.getCoverUrl(this.book, this.size)
      }
      return ''
    }
  },
  async created() {
    const typeset = get(this.book, 'type') || ''
    if (typeset.startsWith('A4')) {
      this.height = 'a4'
      return
    }
    if (typeset.startsWith('A5')) {
      this.height = 'a5'
      return
    }
    const codeName = get(this.book, 'cover.codeName')
    if (codeName) {
      this.height = this.$ls.get('height:' + codeName)
      if (!this.height) {
        const result = await this.$req.get(
          'https://canvas.xinshu.me/config/' + codeName,
          {silent: true}
        )
        this.height = result.height / result.width * 100 + '%'
        this.$ls.set('height:' + codeName, this.height)
      }
    }
    this.height = this.height || 'a5'
  }
}
</script>

<style lang="scss" scoped>
.cover {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 2px;
  transition: opacity .2s;

  .square:after {
    pointer-events: none;
    display: block;
    position: absolute;
  }

  &.plain .square {
    background-color: transparent !important;

    &:after {
      display: none !important;
    }
  }

  &.shadowed .square {
    box-shadow: 0 0 10px rgba(0, 0, 0, .15) !important;
  }

  &.fill {
    &:after {
      content: '';
      display: block;
      position: relative;
      padding-bottom: 142%;
      pointer-events: none;
    }

    &.fill-start .cover-inner {
      justify-content: flex-end;
    }

    .cover-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 0;
    }
  }

  &.album .square:after {
    content: '';
    background-image: url(../assets/images/album/album-shadow.png);
    background-repeat: repeat-y;
    background-size: 100%;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &.wxbook .square:after,
  &.shuoshuo_book .square:after,
  &.wbbook .square:after,
  &.blogbook .square:after,
  &.diarybook .square:after {
    content: '';
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    @include bgi('https://img.xinshu.me/upload/277f88b6d4494701905734ea2051096f!480');
  }

  &.calbum .square:after,
  &.xcalbum .square:after,
  &.xcalbum .square:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url('https://img.xinshu.me/upload/3a43d13d112135fa3d0a70ca055eb8ad!320');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &.calendar-h,
  &.calendar-v,
  &.calendar,
  &.calendar-2021,
  &.calendar-2022 {
    .square {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 5%;
        right: 5%;
        background-image: url(../assets/images/album/ring.png);
        background-repeat: round;
        background-size: contain;
        background-position: 40% 50%;
        margin-top: -2.5%;
        padding-bottom: 5%;
        z-index: 2;
      }
    }
  }
  &.calendar-metal {
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-bottom: 100%;
      background-image: url(../assets/images/album/calendar-metal.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 50% 45%;
      z-index: 10;
      pointer-events: none;
      position: absolute;
      top: 9%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    .cover-inner {
      padding-left: 10%;
      padding-right: 10%;
    }

    ::v-deep .square-image {
      width: 90%;
    }
  }

  &.b5album {
    .square:after {
      opacity: .3;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      @include bgi('https://img.xinshu.me/upload/d9df4448f70046b08b35e537d27cfb32');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.frame-moment {
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-bottom: 100%;
      @include bgi('https://img.xinshu.me/upload/cc8c3ded6aec416399c1358f60461aed!320');
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      z-index: 10;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    .cover-inner {
      padding-left: 13%;
      padding-right: 13%;
    }
  }
  &.postcard {
    transform: scale(0.5);
    .square.v {
      margin: auto;
      width: 70%;
    }
  }
}
</style>
