import Vue from 'vue'
import Router from 'vue-router'
import store from './vuex/store'
import { chain, property } from 'lodash'
import { isVerGreaterThan } from './utils/utils'
import { decodeBookParams } from '@/models/book'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: 'home' */'./pages/Home'),
    meta: {
      miniProgram: ['/pages/books/books', '/pages/product/product', '/pages/index/index']
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: 'signin' */'./pages/Signin')
  },
  {
    path: '/products/:product',
    name: 'product',
    component: () => import(/* webpackChunkName: 'product' */'./pages/Product'),
    meta: {
      nav: 'sm'
    }
  },

  {
    path: '/create/',
    name: 'create',
    component: () => import(/* webpackChunkName: 'create' */'./pages/Create'),
    children: [
      {
        path: '/create/wxbook',
        name: 'create.wxbook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Wxbook')
      }, {
        path: '/create/wxbook-friend',
        name: 'create.wxbookFriend',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/WxbookFriend')
      }, {
        path: '/create/wxbook-private',
        name: 'create.wxbookPrivate',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/WxbookPrivate')
      }, {
        path: '/create/wbbook',
        name: 'create.wbbook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Wbbook')
      }, {
        path: '/create/wbbook-friend',
        name: 'create.wbbookFriend',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/WbbookFriend')
      }, {
        path: '/create/diarybook',
        name: 'create.diarybook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/DiaryBook')
      }, {
        path: '/create/qbbbook',
        name: 'create.qbbbook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Qbbbook')
      }, {
        path: '/create/bbsbook',
        name: 'create.bbsbook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Bbsbook')
      }, {
        path: '/create/album',
        name: 'create.album',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Album')
      }, {
        path: '/create/qbook',
        name: 'create.qbook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/QBook')
      }, {
        path: '/create/qzbook',
        name: 'create.qzbook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/QzBook')
      }, {
        path: '/create/blogbook/:source',
        name: 'create.blogbook',
        component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Blogbook')
      }
    ],
    meta: {
      private: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/albums',
    name: 'albums',
    component: () => import(/* webpackChunkName: 'create' */'./pages/Albums'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/albums',
    name: 'albums',
    component: () => import(/* webpackChunkName: 'create' */'./pages/Albums'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/scenarios/:id',
    name: 'scenario',
    component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Scenario'),
    meta: {
      private: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/create/examples',
    name: 'examples',
    component: () => import(/* webpackChunkName: 'create' */'./pages/Create/Examples'),
    meta: {
      private: true
    }
  },
  {
    name: 'createCAlbum',
    path: '/create/calbum',
    component: {
      template: '<div>请稍候...</div>',
      created() {
        this.$router.replace({
          name: 'create.album',
          query: {...this.$route.query, tid: 'calbum'}
        })
      }
    },
    meta: {
      private: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },

  {
    path: '/books',
    name: 'books',
    component: () => import(/* webpackChunkName: 'books' */'./pages/Books'),
    meta: {
      private: true,
      miniProgram: '/pages/books/books'
    }
  },
  {
    path: '/redeem',
    name: 'redeem',
    component: () => import(/* webpackChunkName: 'workshop' */'./pages/Redeem'),
    meta: {
      private: true,
      footer: 'hidden'
    }
  },

  {
    path: '/workshop',
    name: 'workshop',
    component: () => import(/* webpackChunkName: 'workshop' */'./pages/Workshop/Books'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/workshop/redeem',
    name: 'workshop.redeem',
    component: () => import(/* webpackChunkName: 'workshop' */'./pages/Workshop/Redeem'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/workshop/redirect',
    name: 'workshop.redirect',
    component: () => import(/* webpackChunkName: 'workshop' */'./pages/Workshop/Redirect'),
    meta: {
      nav: 'hidden',
      footer: 'hidden'
    }
  },

  {
    path: '/weimeng',
    name: 'weimeng',
    component: () => import(/* webpackChunkName: 'weimeng' */'./pages/Weimeng/Books'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/weimeng/products',
    name: 'weimeng.products',
    component: () => import(/* webpackChunkName: 'weimeng' */'./pages/Weimeng/Products'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/weimeng/redeem',
    name: 'weimeng.redeem',
    component: () => import(/* webpackChunkName: 'weimeng' */'./pages/Weimeng/Redeem'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/weimeng/redirect',
    name: 'weimeng.redirect',
    component: () => import(/* webpackChunkName: 'weimeng' */'./pages/Weimeng/Redirect'),
    meta: {
      nav: 'hidden',
      footer: 'hidden'
    }
  },

  // 博客书 - 导入文档
  {
    path: '/books/:bookId/word',
    name: 'book.blogbook.word',
    component: () => import(/* webpackChunkName: 'word' */'./pages/BlogbookWord.vue'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  // 博客书 - 导入我的作品
  {
    path: '/books/:bookId/creation',
    name: 'book.blogbook.creation',
    component: () => import(/* webpackChunkName: 'word' */'./pages/Creation.vue'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  // 博客书 - 编辑内容
  {
    path: '/books/:bookId/editblogbook/:aid',
    name: 'book.blogbook.edit',
    component: () => import(/* webpackChunkName: 'blogbook' */'./pages/EditBlogbook.vue'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  // 博客书 - 编辑目录
  {
    path: '/books/:bookId/catalog',
    name: 'book.blogbook.catalog',
    component: () => import(/* webpackChunkName: 'blogbook' */'./pages/BlogbookCatalog.vue'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  // 博客书 - 恢复目录
  {
    path: '/books/:bookId/catalogrestore',
    name: 'book.blogbook.restore',
    component: () => import(/* webpackChunkName: 'blogbook' */'./pages/BlogbookCatalogRestore.vue'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/books/:bookId',
    name: 'book',
    component: () => import(/* webpackChunkName: 'preview' */'./pages/Book'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    },
    children: [
      {
        name: 'book.inner',
        path: '/books/:bookId/months/:month',
        component: () => import(/* webpackChunkName: 'preview' */'./pages/Book/Inner')
      }
    ]
  },
  {
    path: '/books/:bookId/edit/:month?',
    name: 'book.edit',
    component: () => import(/* webpackChunkName: 'edit' */'./pages/Edit'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/books/:bookId/restore/:month',
    name: 'book.restore',
    component: () => import(/* webpackChunkName: 'edit' */'./pages/Restore'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/books/:bookId/months',
    name: 'book.months',
    component: () => import(/* webpackChunkName: 'edit' */'./pages/MonthSelection'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/books/:bookId/update',
    name: 'book.update',
    component: () => import(/* webpackChunkName: 'edit' */'./pages/Update'),
    meta: {
      native: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    path: '/books/:bookId/design/preface',
    name: 'book.preface',
    component: () => import(/* webpackChunkName: 'edit' */'./pages/Design/Preface'),
    meta: {
      native: true,
      nav: 'sm'
    }
  },
  {
    path: '/books/:bookId/design/acknowledgement',
    name: 'book.acknowledgement',
    component: () => import(/* webpackChunkName: 'edit' */'./pages/Design/Acknowledgement'),
    meta: {
      native: true,
      nav: 'sm'
    }
  },
  {
    path: '/books/:bookId/design/copyright',
    name: 'book.design.copyright',
    component: () => import(/* webpackChunkName: 'design' */'./pages/Design/Copyright'),
    meta: {
      nav: 'sm'
    }
  },
  {
    path: '/books/:bookId/design',
    name: 'book.design',
    component: () => import(/* webpackChunkName: 'design' */'./pages/Design'),
    meta: {
      native: true,
      private: true,
      footer: 'hidden'
    },
    children: [
      {
        path: 'cover',
        name: 'book.design.cover',
        component: () => import(/* webpackChunkName: 'design' */'./pages/Design/CoverDesign'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: 'template',
        name: 'book.design.template',
        component: () => import(/* webpackChunkName: 'design' */'./pages/Design/TemplateDesign'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: 'insert',
        name: 'book.design.insert',
        component: () => import(/* webpackChunkName: 'design' */'./pages/Design/InsertDesign'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: 'background',
        name: 'book.design.background',
        component: () => import(/* webpackChunkName: 'design' */'./pages/Design/BackgroundDesign'),
        meta: {
          nav: 'sm'
        }
      }
    ]
  },

  {
    path: '/book-share/:bookId',
    name: 'bookShare',
    component: () => import(/* webpackChunkName: 'share' */'./pages/BookPreview'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    }
  },

  {
    path: '/books/:bookId/share',
    name: 'bookPreview',
    component: () => import(/* webpackChunkName: 'share' */'./pages/BookShare'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    }
  },

  {
    name: 'packages',
    path: '/packages',
    component: () => import(/* webpackChunkName: 'package' */'./pages/Packages'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    }
  },

  {
    name: 'package',
    path: '/packages/:id',
    component: () => import(/* webpackChunkName: 'package' */'./pages/Package'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    }
  },
  {
    name: 'packageBuy',
    path: '/packages/:id/buy',
    component: () => import(/* webpackChunkName: 'package' */'./pages/PackageBuy'),
    meta: {
      private: true,
      nav: 'sm',
      footer: 'hidden'
    }
  },

  {
    path: '/albums/guide',
    name: 'albumGuide',
    component: () => import(/* webpackChunkName: 'album' */'./pages/Album/AlbumGuideZone')
  },

  {
    path: '/albums/:albumId',
    name: 'album',
    component: () => import(/* webpackChunkName: 'album' */'./pages/Album'),
    meta: {
      nav: 'sm',
      footer: 'hidden'
    }
  },

  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: 'my' */'./pages/My'),
    meta: {
      private: true,
      miniProgram: '/pages/my/my'
    },
    children: [
      {
        path: '/my/referral',
        name: 'referral',
        component: () => import(/* webpackChunkName: 'referral' */'./pages/My/Referral'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/referral/poster',
        name: 'poster',
        component: () => import(/* webpackChunkName: 'referral' */'./pages/My/Poster'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/referral/fans',
        name: 'fans',
        component: () => import(/* webpackChunkName: 'referral' */'./pages/My/Fans'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/referral/revenue',
        name: 'revenue',
        component: () => import(/* webpackChunkName: 'referral' */'./pages/My/Revenue'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/wallet',
        name: 'wallet',
        component: () => import(/* webpackChunkName: 'my' */'./pages/My/Wallet'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/promotions',
        name: 'promotions',
        component: () => import(/* webpackChunkName: 'my' */'./pages/My/Promotions'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/coupons/:couponNo',
        name: 'claimCoupon',
        component: () => import(/* webpackChunkName: 'my' */'./pages/My/ClaimCoupon'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/balance/details',
        name: 'balanceDetails',
        component: () => import(/* webpackChunkName: 'recharge' */'./pages/My/BalanceDetail'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/recharge',
        name: 'recharge',
        component: () => import(/* webpackChunkName: 'recharge' */'./pages/My/Recharge'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/recharge-orders/:orderNo/result',
        name: 'rechargeOrderResult',
        component: () => import(/* webpackChunkName: 'recharge' */'./pages/My/RechargeOrderResult'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/reset-password',
        name: 'reset',
        component: () => import(/* webpackChunkName: 'my' */'./pages/My/Reset')
      }, {
        path: '/my/bind-phone',
        name: 'bind',
        component: () => import(/* webpackChunkName: 'my' */'./pages/My/Bind')
      }, {
        path: '/my/vip',
        name: 'vip',
        component: () => import(/* webpackChunkName: 'my' */'./pages/My/Vip'),
        meta: {
          nav: 'sm'
        }
      }, {
        path: '/my/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: 'feedback' */'./pages/Feedback'),
        meta: {
          private: true
        }
      }
    ]
  },

  {
    path: '/my/agent/intro',
    name: 'agent.intro',
    component: () => import(/* webpackChunkName: 'agent' */'./pages/Agent/Intro')
  },
  {
    path: '/my/agent/apply',
    name: 'agent.apply',
    component: () => import(/* webpackChunkName: 'agent' */'./pages/Agent/Apply'),
    meta: {
      private: true
    }
  },

  {
    path: '/unboxing',
    name: 'unboxing',
    component: () => import(/* webpackChunkName: 'library' */'./pages/Library/Unboxing')
  },
  {
    path: '/unboxing/:id',
    name: 'unboxingDetail',
    component: () => import(/* webpackChunkName: 'library' */'./pages/Library/UnboxingDetail')
  },

  {
    path: '/books/:bookId/check',
    name: 'check',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Check'),
    meta: {
      private: true,
      footer: 'md',
      nav: 'md'
    }
  },
  {
    path: '/books/:bookId/buy',
    name: 'buy',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Buy'),
    meta: {
      private: true,
      footer: 'md',
      nav: 'md'
    }
  },
  {
    path: '/books/:bookId/buy/agent',
    name: 'agentBuy',
    component: () => import(/* webpackChunkName: 'order' */'./pages/AgentBuy'),
    meta: {
      private: true,
      footer: 'hidden',
      nav: 'hidden'
    }
  },
  {
    path: '/pay/:orderNo',
    name: 'pay',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Pay'),
    meta: {
      footer: 'hidden',
      private: true,
      nav: 'hidden'
    }
  },
  {
    path: '/pay/:orderNo/alipay',
    name: 'alipay',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Alipay'),
    meta: {
      footer: 'hidden',
      nav: 'hidden'
    }
  },
  {
    path: '/pay/:orderNo/taobao',
    name: 'taobao',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Taobao'),
    meta: {
      footer: 'hidden',
      nav: 'hidden'
    }
  },
  {
    path: '/orders/:orderNo/result',
    name: 'orderResult',
    component: () => import(/* webpackChunkName: 'order' */'./pages/OrderResult'),
    meta: {
      private: true,
      nav: 'sm'
    }
  },

  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Orders'),
    meta: {
      native: true,
      private: true
    }
  },
  {
    path: '/orders/:orderNo',
    name: 'order',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Order'),
    meta: {
      private: true,
      footer: 'hidden',
      nav: 'sm'
    }
  },
  {
    path: '/orders/:orderNo/cashback',
    name: 'cashback',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Cashback'),
    meta: {
      private: true
    }
  },
  {
    path: '/orders/:orderNo/repair',
    name: 'repair',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Repair'),
    meta: {
      private: true
    }
  },
  {
    path: '/orders/:orderNo/edit',
    name: 'editOrder',
    component: () => import(/* webpackChunkName: 'order' */'./pages/EditOrder'),
    meta: {
      private: true
    }
  },
  {
    path: '/orders/:orderNo/pdf',
    name: 'orderPdf',
    component: () => import(/* webpackChunkName: 'order' */'./pages/Pdf'),
    meta: {
      private: true
    }
  },

  {
    path: '/package-orders',
    name: 'packageOrders',
    component: () => import(/* webpackChunkName: 'package-order' */'./pages/PackageOrders'),
    meta: {
      private: true
    }
  },
  {
    path: '/package-orders/:orderNo',
    name: 'packageOrder',
    component: () => import(/* webpackChunkName: 'package-order' */'./pages/PackageOrder'),
    meta: {
      private: true
    }
  },

  // 亲宝宝书小编
  {
    path: '/qbbassistant',
    name: 'qbbAssistant',
    component: () => import(/* webpackChunkName: 'assistant' */'./pages/QbbAssistant'),
    meta: {
      nav: 'hidden',
      footer: 'hidden',
      private: true
    }
  },
  // 宝宝树小编
  {
    path: '/bbsassistant',
    name: 'bbsAssistant',
    component: () => import(/* webpackChunkName: 'assistant' */'./pages/BbsAssistant'),
    meta: {
      nav: 'hidden',
      footer: 'hidden',
      private: true
    }
  },
  // 博客书小编
  {
    path: '/wxgzhassistant',
    name: 'wxgzhAssistant',
    component: () => import(/* webpackChunkName: 'assistant' */'./pages/BlogbookAssistant'),
    meta: {
      footer: 'hidden',
      private: true
    }
  },
  // 搜索公众号导入
  {
    path: '/wxmpassistant',
    name: 'wxmpAssistant',
    component: () => import(/* webpackChunkName: 'assistant' */'./pages/GzhAssistant'),
    meta: {
      footer: 'hidden',
      private: true
    }
  },

  {
    path: '/assistant',
    name: 'assistant',
    component: () => import(/* webpackChunkName: 'assistant' */'./pages/Assistant'),
    meta: {
      footer: 'hidden'
    }
  },

  {
    path: '/promotions/seven-year',
    name: 'sevenYear',
    component: () => import('./pages/Promotions/SevenYear'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/nasa',
    name: 'nasa',
    component: () => import('./pages/Promotions/Nasa'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/lottery-2020/:objectId?',
    name: 'lottery2020',
    component: () => import('./pages/Promotions/Lottery2020'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/lottery-2021/:objectId?',
    name: 'lottery2021',
    component: () => import('./pages/Promotions/Lottery2021'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/7th-anniversary',
    name: '7thAnniversary',
    component: () => import('./pages/Promotions/7thAnniversary'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/7th-prize',
    name: '7thPrize',
    component: () => import('./pages/Promotions/7thAnniversaryPrize'),
    meta: {
      private: true
    }
  },
  {
    path: '/promotions/myhistory/:date?',
    name: 'myhistory',
    component: () => import('./pages/Promotions/MyHistory'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/wechat-reading/',
    name: 'wechatReading',
    component: () => import('./pages/Promotions/WechatReading'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/weixinshu-7th/',
    name: 'weixinshu7th',
    component: () => import('./pages/Promotions/Weixinshu7th'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/double-eleven/',
    name: 'doubleEleven2021',
    component: () => import('./pages/Promotions/DoubleEleven2021'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/national-2020/',
    name: 'national2020',
    component: () => import('./pages/Promotions/NationalDay2020'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/teachersday2021/',
    name: 'teachersDay2021',
    component: () => import('./pages/Promotions/TeachersDay2021'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/panic-buy/',
    name: 'panicBuy',
    component: () => import('./pages/Promotions/PanicBuy'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/redpack2022/',
    name: 'redpack2022',
    component: () => import('./pages/Promotions/Redpack2022'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/8th-anniversary',
    name: '8thAnniversary',
    component: () => import('./pages/Promotions/8thAnniversary'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },

  {
    path: '/games/color-blindness',
    name: 'colorBlindness',
    component: () => import(/* webpackChunkName: 'games' */'./pages/Games/ColorBlindness'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/games/dressup-avatar',
    name: 'changeAvatar',
    component: () => import(/* webpackChunkName: 'games' */'./pages/Games/ChangeAvatar'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/games/national-avatar',
    name: 'changeAvatar',
    component: () => import(/* webpackChunkName: 'games' */'./pages/Games/ChangeAvatar'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/games/xinshu-avatar',
    name: 'changeAvatar',
    component: () => import(/* webpackChunkName: 'games' */'./pages/Games/ChangeAvatar'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/collection-2022',
    name: 'collection2022',
    component: () => import(/* webpackChunkName: 'games' */'./pages/Promotions/Collection2022'),
    meta: {
      private: true,
      nav: 'hidden',
      footer: 'hidden'
    }
  },
  {
    path: '/promotions/collection',
    name: 'collection',
    component: () => import('./pages/Promotions/Collection'),
    meta: {
      private: true,
      navClass: 'd-none',
      footerClass: 'd-none',
      title: '这是你做微信书第几年'
    }
  },

  {
    name: 'pages',
    path: '/pages/:page',
    component: {
      template: '<div class="text-center">正在跳转</div>',
      beforeRouteEnter(to) {
        location.replace('/pages/' + to.params.page)
      }
    }
  },
  {
    name: 'legacyPreview',
    path: '/app/preview/:id/:section',
    component: () => import('./pages/LegacyPreview'),
    meta: {
      footer: 'hidden'
    }
  },
  {
    path: '*',
    name: '404',
    component: require('./pages/404').default
  }
]

const redirects = [
  {path: '/diary/books', redirect: '/diary'},
  {path: '/share/:id', redirect: '/book-share/:id'},
  {path: '/weibo-share/:id', redirect: '/book-share/:id'},
  {path: '/library', redirect: '/unboxing'},
  {path: '/exhibition', redirect: '/unboxing'},
  {path: '/library/unboxing', redirect: '/unboxing'},
  {path: '/create/diary', redirect: '/create/diarybook'},
  {path: '/create/blogbook', redirect: '/create/blogbook/xinshu'},
  {path: '/library/introduction', redirect: '/'},
  {path: '/my/coupon/:couponNo', redirect: '/my/coupons/:couponNo'},
  {path: '/books/:bookId/preview', redirect: '/book-share/:bookId'},
  {path: '/my/balance', redirect: '/my/wallet'},
  {path: '/my/coupons', redirect: '/my/wallet'},
  {path: '/my/referral/revenue/*', redirect: '/my/referral/revenue'},
  {path: '/alipay/:orderNo', redirect: '/pay/:orderNo/alipay'},
  {path: '/create/shuoshuo_book', redirect: '/create/qbook'},
  {path: '/create/albums', redirect: '/albums', name: 'create.albums'}
]
redirects.forEach(i => routes.unshift(i))

const router = new Router({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router

router.beforeEach(async (to, from, next) => {
  const isMiniProgram = window.__wxjs_environment === 'miniprogram'
  const {name, meta, matched, query, params} = to
  const app = router.app

  const $ss = app.$ss
  const $store = store
  const state = $store.state

  if (/workshop/.test(to.name)) {
    $store.commit('setSource', 'workshop')
  }

  if (/weimeng/.test(to.name)) {
    $store.commit('setSource', 'weimeng')
  }

  if (/^agent_/.test(state.token)) {
    $store.commit('setSource', 'agent')
  }

  if (matched && matched.length) {
    meta.private = matched.some(property('meta.private'))
    meta.restricted = matched.some(property('meta.restricted'))
    meta.native = matched.some(property('meta.native'))

    meta.nav = chain(matched).map(property('meta.nav')).filter(Boolean).last().value()
    meta.footer = chain(matched).map(property('meta.footer')).filter(Boolean).last().value()
  }

  document.body.setAttribute('data-page', name || '')
  document.body.setAttribute('data-nav', meta.nav || '')
  document.body.setAttribute('data-footer', meta.footer || '')

  if (meta.miniProgram && isMiniProgram && window.wx) {
    // 小程序 webview 内根据 meta 配置调用 JSSDK 跳转方法
    if (!Array.isArray(meta.miniProgram)) {
      meta.miniProgram = [meta.miniProgram]
    }

    for (const url of meta.miniProgram) {
      try {
        window.wx.miniProgram.redirectTo({url})
        window.wx.miniProgram.switchTab({url})
      } catch (err) {
      }
    }
  }

  if (window.isApp && meta.native) {
    try {
      if (window.isIOS && isVerGreaterThan(window.appVersion, '1.0.0')) {
        console.log('iOS postMessage')
        window.webkit.messageHandlers.navigateTo.postMessage(JSON.stringify({url: to.fullPath}))
        return next(false)
      }

      if (window.isAndroid) {
        console.log('Android postMessage')
        window.wxs.navigateTo(JSON.stringify({url: to.fullPath}))
        return next(false)
      }
    } catch (err) {
    }
  }

  if (query.ncr && state.source) {
    $ss.removeItem('source')
    $store.commit('resetAuth')
    $store.commit('setSource', '')
  }

  const userSource = state.source
  const queryToken = query.token

  if (queryToken) {
    $store.commit('setToken', {authToken: queryToken})
    // 小程序就不需要跳转了
    if (!isMiniProgram) {
      if (name !== 'signin') {
        delete query.token
      }
      return next({name, query, params})
    }
  }

  if (userSource === 'agent') {
    const agentUserRoutes = [
      'book',
      'edit',
      'restore',
      'assistant',
      'design',
      'write',
      'album',
      'check',
      'buy',
      'agentBuy',
      'pay',
      'alipay',
      'order',
      'signin',
      '404'
    ]
    if (!agentUserRoutes.includes(to.name.split('.')[0])) {
      // 限制访问页面
      const lastVisited = localStorage.getItem('agentBookId')
      if (lastVisited) {
        return next('/books/' + lastVisited)
      }
      return next({name: '404'})
    }

    if (to.params.bookId) {
      localStorage.setItem('agentBookId', to.params.bookId)
    }
  }

  if (userSource === 'workshop') {
    const workshopUserRoutes = [
      'workshop',
      'workshop.redeem',
      'workshop.redirect',
      'create.album',
      'book',
      'album',
      'check',
      'buy',
      'pay',
      'alipay',
      'order',
      'signin'
    ]
    if (!state.token && !window.isWechat && !['workshop.redirect', 'signin'].includes(to.name)) {
      // 非微信中，未登录的全部重定向到提示微信使用页面
      return next('/workshop/redirect')
    }
    if (!workshopUserRoutes.includes(to.name)) {
      // 限制访问的页面
      return next('/workshop')
    }
  }

  if (userSource === 'weimeng') {
    const weimengUserRoutes = [
      'weimeng',
      'weimeng.redirect',
      'weimeng.products',
      'weimeng.redeem',
      'create.album',
      'book',
      'album',
      'check',
      'buy',
      'pay',
      'alipay',
      'order',
      'signin'
    ]
    if (!state.token && !window.isWechat && !['weimeng.redirect', 'signin'].includes(to.name)) {
      // 非微信中，未登录的全部重定向到提示微信使用页面
      return next('/weimeng/redirect')
    }
    if (!weimengUserRoutes.includes(to.name)) {
      // 限制访问的页面
      return next('/weimeng')
    }
  }

  if (meta.private && !state.token) {
    // 当路由为 private 时，重定向到登录页面
    if (!window.isWechat) {
      app.$alert.warn(meta.alert || '请先登录才能继续哦')
    }
    return next('/signin?auto=1&redirect=' + encodeURIComponent(to.fullPath))
  }

  if (userSource !== 'agent' && state.token && state?.user?.token !== state.token) {
    try {
      const user = await app.$ajax.fetchUserInfo()
      app.$store.commit('setUser', user)
    } catch (err) {
      if (err?.errcode === 21003) {
        app.$store.commit('resetAuth')
        return next('/signin?auto=1&redirect=' + encodeURIComponent(to.fullPath))
      }
    }
  }

  if (to.path.startsWith('/api')) {
    return next('/books')
  }

  if (to.params.bookId) {
    to.meta.bookParams = decodeBookParams(to.params.bookId)
  }

  next()
})

router.afterEach(() => {
  const $store = router.app.$store
  $store.commit('setError', null)
})
