<template>
  <button class="btn qiyu" :class="classNames"
          @click="activate" type="button" :disabled="loading">
    <fa icon="spinner" spin v-if="loading"/>
    <fa icon="headset" v-else/>
    <span v-if="loading">正在连接</span>
    <slot v-else-if="$slots.default"></slot>
    <span v-else>{{text}}</span>
  </button>
</template>

<script>
import loadJS from 'load-script-once'

/* global ysf */
export default {
  name: 'qiyu',
  data() {
    return {
      loading: false
    }
  },
  props: {
    variant: {
      default: 'success'
    },
    size: String,
    block: Boolean,
    text: {
      default: '联系客服'
    }
  },
  computed: {
    classNames() {
      const list = []
      if (this.variant) {
        list.push('btn-' + this.variant)
      }
      if (this.size) {
        list.push('btn-' + this.size)
      }
      if (this.block) {
        list.push('btn-block')
      }
      return list
    }
  },
  methods: {
    async activate() {
      if (this.isMinProgram) {
        this.$alert.error('请从个人中心中点击联系客服按钮')
        return
      }
      if (!window.ysf) {
        this.loading = true
        await this.init()
        await this.config(this.user || {})
      }
      ysf('open')
      const interval = setInterval(() => {
        if (document.querySelector('.ysf-chat-layeropen')) {
          this.loading = false
          clearInterval(interval)
        }
        ysf('open')
      }, 200)
    },
    init() {
      return loadJS('https://qiyukf.com/script/420277c7936502387f461f5505badc66.js').then(() => {
        return new Promise(resolve => {
          ysf('onready', () => {
            resolve()
          })
        })
      })
    },
    config({openid, nickname, email, mobile}) {
      if (openid === 'undefined' || openid === null || openid === undefined) {
        openid = ''
      }
      return new Promise((resolve, reject) => {
        ysf('config', {
          uid: openid,
          name: nickname,
          email: email,
          mobile: mobile,
          success: resolve,
          error: reject
        })
      })
    }
  }
}
</script>

<style>
  #YSF-BTN-HOLDER {
    display: none !important;
  }
</style>
