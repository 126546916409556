import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import loadJS from 'load-script-once'

import './filters'
import './directives'

import './bootstrap-vue'
import './utils/polyfill'

Vue.use(require('vue-masonry-css').default)
Vue.use(require('vue-gtag'), {
  config: {id: 'G-HJBPBGMN3Z'}
}, router)

Vue.use(require('./plugins/ajax').plugin)
Vue.use(require('./plugins/alert').plugin)
Vue.use(require('./plugins/dialog').plugin)
Vue.use(require('./plugins/moment').plugin)
Vue.use(require('./plugins/request').plugin)
Vue.use(require('./plugins/storage').plugin)

Vue.mixin(require('./mixins/filters').default)
Vue.mixin(require('./mixins/page-title').default)
Vue.mixin(require('./mixins/user-agent').default)
Vue.mixin(require('./mixins/global-data').default)
Vue.mixin(require('./mixins/global-events').default)
Vue.mixin(require('./mixins/error-handler').default)

/* globalComponents */
Vue.component('Popup', require('./components/Popup').default)
Vue.component('Divider', require('./components/Divider').default)
Vue.component('Avatar', require('./components/Avatar').default)
Vue.component('Cover', require('./components/Cover').default)
Vue.component('ScrollTip', require('./components/ScrollTip').default)
Vue.component('SelectGroup', require('./components/SelectGroup').default)
Vue.component('Loading', require('./components/Loading').default)
Vue.component('BookIcon', require('./components/BookIcon').default)
Vue.component('Ske', require('./components/Skeleton').default)
Vue.component('Empty', require('./components/Empty').default)
Vue.component('Qiyu', require('./components/Qiyu').default)
Vue.component('Emotion', require('./components/Emotion').default)
Vue.component('Square', require('./components/Square').default)
Vue.component('ImageText', require('./components/ImageText').default)
Vue.component('Grid', require('./components/Grid').default)
Vue.component('GridItem', require('./components/GridItem').default)
Vue.component('Cell', require('./components/Cell').default)
Vue.component('CellLink', require('./components/CellLink').default)
Vue.component('PageTitle', require('./components/PageTitle').default)
Vue.component('Tips', require('./components/Tips').default)
Vue.component('BottomBar', require('./components/BottomBar').default)
Vue.component('Fa', require('./components/FontAwesomeIcon').default)
Vue.component('Datetime', require('./components/Datetime').default)
/* globalComponents */

Vue.config.productionTip = false

/* eslint-disable no-new */
export default new Vue({
  el: '#app',
  store,
  router,
  data() {
    return {
      innerWidth: window.innerWidth,
      error: null
    }
  },
  template: '<App/>',
  components: {App}
})

if (window.isiPhoneX) {
  document.body.classList.add('is-iphone-x')
}

if (window.isWechat) {
  document.body.classList.add('is-wechat')
  /* global WeixinJSBridge */
  if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
    handleFontSize()
  } else {
    document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
  }
}

if (!window.isDev) {
  window.__bl = {
    config: {
      pid: 'ibbb5lkny7@d942be4ecc0875f',
      appType: 'web',
      imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
      ignoreUrlCase: false,
      behavior: true,
      ignore: {
        ignoreUrls: [
          (url) => !/xinshu\.me|weixinshu\.com|^\//.test(url)
        ],
        ignoreErrors: [
          /Can't find variable: objectInnerText/,
          /Redirected when going from/,
          /^Script error\.?$/,
          /^Network Error$/,
          /Request aborted/,
          /timeout of \w+? exceeded/,
          /window\.ucbrowser/,
          /用户未登录|资源不存在/,
          /Navigation cancelled/
        ]
      }
    }
  }
  loadJS('https://retcode.alicdn.com/retcode/bl.js')
}

window.canUseWebp = (() => {
  try {
    return document.createElement('canvas').toDataURL('image/webp').includes('data:image/webp')
  } catch (err) {
    return false
  }
})()

if (window.canUseWebp) {
  document.body.classList.add('webp')
}

function handleFontSize() {
  WeixinJSBridge.invoke('setFontSizeCallback', {fontSize: 0})
  WeixinJSBridge.on('menu:setfont', function () {
    WeixinJSBridge.invoke('setFontSizeCallback', {fontSize: 0})
  })
}
