export default {
  methods: {
    $rmb(num, operator) {
      if (!num && num !== 0) {
        return num
      }
      const isNegative = num < 0
      let prefix = '￥'
      if (isNegative) {
        prefix = '￥-'
      } else if (operator) {
        prefix = '￥+'
      }
      num = Math.abs(Number(num)).toFixed(2)
      return prefix + num.replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,')
    }
  }
}
