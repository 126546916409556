/**
 * Created by chenzhuokai on 2017/1/9.
 */
import Vue from 'vue'

Vue.directive('wordCount', {
  inserted(el) {
    if (/input|textarea/i.test(el.tagName)) {
      const labelEl = document.createElement('label')
      labelEl.insertAdjacentHTML('afterbegin', '<span class="words">0</span>')
      if (!el.id) {
        el.id = Math.random().toString(36).substring(7)
      }
      labelEl.setAttribute('for', el.id)
      const maxLength = el.maxLength
      if (maxLength > 0) {
        labelEl.insertAdjacentText('beforeend', ' / ' + maxLength + '字')
      }
      labelEl.className = 'word-count'

      el.insertAdjacentHTML('afterend', labelEl.outerHTML)
      const wordsEl = el.nextElementSibling.querySelector('.words')
      el.inputHandler = () => {
        wordsEl.innerHTML = el.value.length
      }
      el.addEventListener('input', el.inputHandler, false)
      setTimeout(() => {
        wordsEl.innerHTML = el.value.length
      })
      el.labelEl = labelEl
      el.wordEl = wordsEl
    }
  },
  unbind(el) {
    el.removeEventListener('input', el.inputHandler)
    el.labelEl?.remove?.()
    el.wordsEl?.remove?.()
  }
})
