import dayjs from 'dayjs'
import _, { forEach, get, groupBy } from 'lodash'

import { base64Url, getKey, serializeToFormString } from '@/utils/utils'
import request from '@/plugins/request'
import {
  fetchBlogbook,
  fetchBlogbookCatalog,
  fetchBlogbookLayouts,
  fetchBlogbooks
} from './blogbook'

import * as album from './album'
import { bookProducts, bookTypes } from '@/config'
import { snakeCaseObj } from '@/utils/camelcase'
import mutations from '@/vuex/mutations'
import { state } from '@/vuex/store'
import { extendAlbum } from './album'

export function fetchBooks({bookType, size = 12, page}) {
  if (!bookType) {
    return Promise.all([
      fetchBooks({bookType: 'wxbook', size: 1}).catch(() => []),
      fetchBooks({bookType: 'albums', size: 1}).catch(() => []),
      fetchBooks({bookType: 'wbbook', size: 1}).catch(() => []),
      fetchBooks({bookType: 'diarybook', size: 1}).catch(() => []),
      fetchBooks({bookType: 'shuoshuo_book', size: 1}).catch(() => []),
      fetchBooks({bookType: 'qbbbook', size: 1}).catch(() => []),
      fetchBooks({bookType: 'bbsbook', size: 1}).catch(() => []),
      fetchBlogbooks({size: 1}).catch(() => [])
    ]).then(results => {
      const books = []
      results.forEach(i => {
        if (i[0]) {
          i[0].totalCount = i.totalCount
          books.push(i[0])
        }
      })
      return books
    })
  }

  if (bookType.startsWith('album')) {
    return album.fetchAlbums({page, size})
  }

  if (bookType === 'blogbook') {
    return fetchBlogbooks({page, size})
  }

  return request.get('/api/book/books', {
    params: {
      page,
      page_size: size,
      book_type: bookType
    }
  }).then(books => {
    books.forEach(extendBook)
    return books
  })
}

export async function fetchBook({bookId, agent}, fallThrough) {
  try {
    let book
    const {id, bookType} = decodeBookParams(bookId)

    if (isAlbum(bookType)) {
      book = await album.fetchAlbum({albumId: id})
    } else if (bookType === 'star-wbbook') {
      book = await fetchWBBook({id})
    } else if (bookType === 'blogbook') {
      book = await fetchBlogbook({bid: id})
    } else {
      book = await createBookRequest(
        bookId,
        '/api/book/{id}/info?book_type={type}&author={sourceId}&source_type_id={sourceTypeId}',
        {},
        {silent: fallThrough}
      )
    }

    if (book.tid) {
      book = extendAlbum(book)
    } else {
      book = extendBook(book)
    }

    if (agent && book.agentOpenid) {
      book.agentInfo = await request.get('/api/agents/info/' + book.agentOpenid)
    }

    if (agent && book.user?.fromUid) {
      book.agentInfo = await request.get('/api/agents/info/' + book.user.fromUid)
      book.agentOpenid = book.agentInfo?.openid
    }

    return book
  } catch (err) {
    if (fallThrough) {
      return {}
    }
    throw err
  }
}

export function fetchBookPages({bookId, month}) {
  const {id, bookType} = decodeBookParams(bookId)
  if (bookType === 'blogbook') {
    return fetchBlogbookLayouts({bid: id, aid: month})
  }
  return createBookRequest(
    bookId,
    '/api/book/{id}/month/{month}?book_type={type}&author={sourceId}',
    {month}
  )
    .then(pages => {
      const startPageNo = get(pages, [0, 'pageOffset']) || 1
      return pages.map((page, index) => {
        delete page.pageOffset
        page.pageNo = startPageNo + index
        page.pageId = index
        return page
      })
    })
}

export function fetchBookItems({bookId, month}) {
  const url = '/api/book/{sourceId}/items/month/{month}?book_type={bookType}&source_type_id={sourceTypeId}'
  return createBookRequest(bookId, url, {month})
    .then(getKey('items'))
    .then(items => items.map(item => {
      item.time = item.time * 1000
      item.text = item.text || ''
      item.pics = item.pics || []
      if (item.type === 'video' && item.pics[1]) {
        const pic = item.pics[1]
        pic.videoSrc = pic.src
        pic.src = 'https://canvas.xinshu.me/generate/wxbook-video?url=' +
          encodeURIComponent(pic.src)
      }
      return item
    }))
}

export function fetchRestoreItems({bookId, month}) {
  const url = '/api/book/{sourceId}/trash_items/month/{month}?book_type={bookType}&source_type_id={sourceTypeId}'
  return createBookRequest(bookId, url, {month})
    .then(getKey('items'))
    .then(items => items.map(item => {
      item.time = item.time * 1000
      item.pics = item.pics || []
      if (item.type === 'video' && item.pics[1]) {
        const pic = item.pics[1]
        pic.videoSrc = pic.src
        pic.src = 'https://canvas.xinshu.me/generate/wxbook-video?url=' +
          encodeURIComponent(pic.src)
      }
      return item
    }))
}

export function fetchRestoreMonths({bookId}) {
  const url = '/api/book/{sourceId}/months/trash?book_type={bookType}&source_type_id={sourceTypeId}'
  return createBookRequest(bookId, url).then(sortMonths)
}

export function fetchMonths({bookId, all, raw}) {
  const {bookType, id} = decodeBookParams(bookId)
  if (bookType === 'blogbook') {
    return fetchBlogbookCatalog({bid: id})
  }
  let url = '/api/book/{id}/book_months?book_type={type}&source_type_id={sourceTypeId}'
  if (all) {
    url = '/api/book/{sourceId}/months?book_type={type}&source_type_id={sourceTypeId}'
  }
  return createBookRequest(bookId, url).then(data => {
    if (raw) {
      return data
    }
    return sortMonths(data)
  })
}

export function fetchBookMeta({bookId}) {
  const {bookType} = decodeBookParams(bookId)
  if (bookType === 'blogbook') {
    return Promise.resolve({hasLiked: false})
  }
  return Promise.all([
    createBookRequest(bookId, '/api/booklike/{type}/{id}'),
    createBookRequest(bookId, '/api/booklike/{type}/{id}/check', null, {silent: true})
      .catch(() => ({}))
  ]).then(results => {
    const hasLiked = !!results[1].openid
    return {...results[0], hasLiked}
  })
}

export function fetchSupporters({count = 24, bookId}) {
  let url = '/api/booklike/{type}/{id}/details?subtype=like'
  if (count !== 999) {
    url += '&count=' + count
  }
  return createBookRequest(bookId, url)
}

export function fetchBookComments({count = 10, bookId}) {
  let url = '/api/booklike/{type}/{id}/details?subtype=comment'
  if (count !== 999) {
    url += '&count=' + count
  }
  return createBookRequest(bookId, url)
}

export function createBookRequest(bookId, url, params = {}, options) {
  const {id, bookType, sourceId, sourceTypeId} = decodeBookParams(bookId)
  url = url
    .replace(/{id}/g, id)
    .replace(/{(bookType|type)}/g, bookType)
    .replace(/{(author|sourceId)}/g, sourceId)
    .replace(/{sourceTypeId}/g, sourceTypeId || '')

  url = url.replace(/&source_type_id=$/, '')

  if (params) {
    const keys = Object.keys(params)
    if (keys.length) {
      keys.forEach(keyName => {
        url = url.replace('{' + keyName + '}', params[keyName])
      })
    }
  }

  return request.get(url, options)
}

function fetchWBBook({id}) {
  return request.get('/weiboshu/books/' + id + '/').then(book => {
    const months = {}
    book.chapters.forEach(item => {
      if (!item[3]) {
        return
      }
      const date = item[0].toString()
      const year = parseInt(date.slice(0, 4))
      const month = parseInt(date.slice(-2))
      months[year] = months[year] || {months: []}
      months[year].months.push(item[3] ? month : -month)
    })
    book.tid = 'star-wbbook'
    book.type = book.layoutType
    book.pages = book.innerPageCount
    book.months = sortMonths(months)
    return book
  })
}

export function extendBook(book) {
  book.isOwner = book.auth === 'edit'
  book.editable = book.isOwner && !book.finalized
  book.firstMonth = dayjs(book.fromDate).add(1, 'day').format('YYYYMM')
  book.bookId = getBookId(book)
  book.typesetName = bookTypes[book.type] || book.type
  book.canUpdate = book.editable &&
    ['wxbook', 'wbbook', 'shuoshuo_book', 'qbbbook', 'bbsbook'].includes(book.bookType)
  return book
}

function sortMonths(data) {
  const results = []

  forEach(data, ({months}, year) => {
    months.forEach(month => {
      results.push({
        hidden: month < 0,
        year: parseInt(year),
        month: Math.abs(month),
        value: year + '年' + ('0' + Math.abs(month)).slice(-2) + '月',
        route: year + ('0' + Math.abs(month)).slice(-2)
      })
    })
  })

  const result = groupBy(results, 'year')

  Object.defineProperties(result, {
    length: {value: results.length},
    plain: {
      value() {
        const array = []
        for (const key of Object.keys(this)) {
          array.push(...this[key])
        }
        return array
      }
    },
    first: {
      value() {
        return this.plain()[0]
      }
    },
    firstValid: {
      value() {
        const plain = this.plain().filter(i => !i.hidden)
        return plain.filter(i => !i.hidden)[0]
      }
    },
    last: {
      value() {
        const plain = this.plain()
        return plain[plain.length - 1]
      }
    },
    lastValid: {
      value() {
        const plain = this.plain().filter(i => !i.hidden)
        return plain[plain.length - 1]
      }
    },
    prev: {
      value(cur, all) {
        const plain = this.plain().filter(i => all ? true : !i.hidden)
        const index = plain.findIndex(i => i.route === cur)
        if (index > 0) {
          return plain[index - 1]
        }
        return null
      }
    },
    next: {
      value(cur, all) {
        const plain = this.plain().filter(i => all ? true : !i.hidden)
        const index = plain.findIndex(i => i.route === cur)
        if (index < plain.length - 1) {
          return plain[index + 1]
        }
        return null
      }
    }
  })

  return result
}

export function getCoverUrl(book, size = 640) {
  const {toDate, fromDate, title, cover = {}, author, tid, meta} = book
  const {pic, subtitle, rotate} = cover

  const codeName = cover.codeName
  const startYear = new Date(fromDate).getFullYear()
  const endYear = new Date(toDate).getFullYear()
  const years = _.chain([startYear, endYear]).uniq().filter(Boolean).join('~').value()

  const query = {
    title,
    size
  }

  if (pic) {
    query.pic = pic.split('!')[0].split('@')[0]
    query['pic[posX]'] = cover.x
    query['pic[posY]'] = cover.y
    if (rotate) {
      query.pic += '@r' + rotate
    }
  }

  if (years) {
    query.years = years
  }

  if (tid && !author) {
    query.subtitle = subtitle || '献给那些美好的回忆'
  } else {
    query.author = author || '佚名'
  }

  if (meta) {
    _.forEach(meta, (val, key) => {
      query[key] = val
    })
  }

  return 'https://canvas.xinshu.me/generate/' + codeName + serializeToFormString(query, true, false)
}

export function getBookId(book, withBookCode) {
  if (!book) {
    return null
  }

  const parts = [
    book.id || book.aid,
    book.tid || book.bookType,
    book.sourceId,
    withBookCode ? book.bookCode : '',
    book.sourceTypeId || ''
  ]

  if (!parts[0]) {
    return null
  }

  if (!parts[4]) {
    parts.splice(4, 1)

    if (!parts[3]) {
      parts.splice(3, 1)
    }
  }

  return base64Url.encode(parts.join(':'))
}

export function decodeBookParams(bookId) {
  if (!bookId) {
    return null
  }
  const encodeId = base64Url.decode(bookId.trim())
  const [id, bookType, sourceId, bookCode, sourceTypeId] = encodeId.split(':')

  return {
    id,
    bookType,
    author: sourceId,
    sourceId,
    type: bookType,
    bookCode,
    sourceTypeId
  }
}

export function isAlbum(bookType) {
  return !Object.keys(bookProducts).includes(bookType)
}

export async function validateBookCode(bookId) {
  const {id, bookType, bookCode} = decodeBookParams(bookId)
  const params = snakeCaseObj({bookId: id, bookType, bookCode})
  const {authToken, expireIn} = await request.get('/api/book/check_agent_book', {params})
  if (!authToken) {
    return
  }
  mutations.setToken(state, {authToken, expireIn})
  return authToken
}
