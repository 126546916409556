<template>
  <div :class="classList" class="grid" :style="styles">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'grid',
  props: {
    cols: {
      type: [String, Number],
      default: 5
    },
    gutter: {
      type: [String, Number],
      default: 8
    },
    sm: [String, Number],
    float: Boolean,
    initial: Boolean,
    scrollable: Boolean
  },
  computed: {
    styles() {
      return {
        '--grid-gutter': parseFloat(this.gutter) / 2 + 'px'
      }
    },
    classList() {
      return [
        this.cols ? 'grid-cols-' + this.cols : '',
        this.sm ? 'grid-cols-sm-' + this.sm : '',
        {
          scrollable: this.scrollable,
          float: this.float || this.initial,
          initial: this.initial
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: calc(var(--grid-gutter) * -1);
  margin-right: calc(var(--grid-gutter) * -1);

  ::v-deep > .grid-item {
    padding: var(--grid-gutter);
  }

  &.float {
    display: block;
    @include clearfix;

    .grid-item {
      float: left;
    }
  }

  &.scrollable {
    @include media-breakpoint-down(sm) {
      -webkit-overflow-scrolling: touch;
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    .grid-item {
      min-width: 7rem;
    }
  }

  &.initial {
    &.grid-cols-3 {
      .grid-item:first-child {
        width: 66.5%;
        max-width: 66.5%;
        flex-basis: 66.5%;
      }
    }

    &.grid-cols-4 {
      .grid-item:first-child {
        width: 50%;
        max-width: 50%;
        flex-basis: 50%;
      }
    }

    &.grid-cols-5 {
      .grid-item:first-child {
        width: 40%;
        max-width: 40%;
        flex-basis: 40%;
      }
    }
  }

  .grid-item {
    flex-basis: 100%;
    width: 100%;
  }

  &.grid-cols-auto > .grid-item, &.grid-cols-sm-auto > .grid-item {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }

  @for $i from 1 through 12 {
    &.grid-cols-#{$i} > .grid-item {
      width: 100% / $i;
      max-width: 100% / $i;
      flex-basis: 100% / $i;
    }
    @include media-breakpoint-up(sm) {
      &.grid-cols-sm-#{$i} > div.grid-item {
        width: 100% / $i;
        max-width: 100% / $i;
        flex-basis: 100% / $i;
      }
    }
  }
}
</style>
