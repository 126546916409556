import Vue from 'vue'
import { chain, get, set } from 'lodash'
import { ls } from '@/plugins/storage'

Vue.directive('ls', {
  inserted: init
})

function init(el, {value}, vNode) {
  const lsKeyName = value || el.id || el.name
  const localValue = ls.get(lsKeyName)
  if (localValue) {
    setValue(el, localValue, vNode)
  }
  el.onchange = () => {
    ls.set(lsKeyName, el.value)
  }
  if (el.value) {
    ls.set(lsKeyName, el.value)
  }
}

function setValue(el, value, vNode) {
  const expression = get(vNode, 'data.model.expression') || chain(vNode.data.directives).find({name: 'model'}).get('expression').value()
  if (expression) {
    set(vNode.context, expression, value)
  } else {
    const event = new Event('input', {bubbles: true})
    el.value = value
    el.dispatchEvent(event)
  }
}
