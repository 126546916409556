<template>
  <footer class="simple-footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <div class="nav-list">
            <a class="nav-item" target="_blank"
               href="https://www.lagou.com/gongsi/34921.html">加入我们</a>
            <a class="nav-item" href="mailto:hi@xinshu.me">联系我们</a>
            <a class="nav-item" target="_blank"
               href="https://weixinshu.com/pages/privacy/index.html">隐私协议</a>
          </div>
        </div>
        <div class="col" style="max-width: 6em;">
          <div class="social-icons">
            <b-link href="javascript:" class="mr-2">
              <fa icon="weixin" fab class="text-success" aria-hidden="true"/>
              <span class="wechat-qrcode">
                <img src="../assets/images/wechat-qrcode.jpg" class="img-fluid"/>
              </span>
            </b-link>
            <a target="_blank" href="http://weibo.com/veibook">
              <fa icon="weibo" fab aria-hidden="true"></fa>
            </a>
          </div>
        </div>
      </div>
      <hr>
      <div class="row align-items-center">
        <div class="col" style="max-width: 22em;">
          <span class="payment mr-3">
            <img src="../assets/images/icons/alipay.svg" class="icon">
            支付宝支付认证
          </span>
          <span class="payment">
            <img src="../assets/images/icons/wxpay.svg" class="icon">
            微信支付认证
          </span>
        </div>
        <div class="col text-right">
          Copyright ©
          2014-{{new Date().getFullYear()}}
          心书
          |
          Powered By
          <b-link href="https://getbootstrap.com" target="_blank" title="Bootstrap">
            <img src="https://img.xinshu.me/upload/85729c4796399a4a2a59f0abd8ade7b6"
                 style="height: 1.2em;">
          </b-link>
          <b-link href="https://cn.vuejs.org" target="_blank" title="Vue.js">
            <img src="https://img.xinshu.me/upload/dd7e25c0f94be7802d4968455e8acffc"
                 style="height: 1.2em;">
          </b-link>
          <b-link href="https://bootstrap-vue.js.org" target="_blank" title="BootstrapVue">
            <img src="https://img.xinshu.me/upload/acd569c03532ca1c582f7c4854fb5120"
                 style="height: 1.2em;">
          </b-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'simpleFooter',
  components: {}
}
</script>

<style lang="scss" scoped>
.simple-footer {
  color: $text-muted;
  border-top: 1px solid $hr-border-color;
  background-color: #fff;
  @include media-breakpoint-down(sm) {
    display: none;
  }

  .container {
    width: 1280px;
    max-width: 100%;
  }

  .nav-list {
    .nav-item {
      margin-right: 2em;
      display: inline-block;
      color: inherit;
      line-height: 2;
      position: relative;

      i {
        font-size: 2em;
        vertical-align: top;
      }

      &:hover {
        color: $red;
        text-decoration: none;

        .mobile-qrcode {
          display: inline-block;
        }
      }
    }
  }

  .mobile-qrcode, .wechat-qrcode {
    display: none;
    position: absolute;
    bottom: 150%;
    left: 0;
    width: 126px;
    height: 126px;
    background: #fff;
    border-radius: 8px;
    box-shadow: $box-shadow;
    transition: .4s ease;
    padding: 2px;
    z-index: 20;

    &:after {
      /*content: '';*/
      display: inline-block;
      border-top: 10px solid #fefefe;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      top: 100%;
      left: 30px;
      width: 20px;
      height: 20px;
    }
  }

  .social-icons a {
    font-size: 1.5rem;
    position: relative;
    text-decoration: none;

    .wechat-qrcode {
      left: auto;
      right: 0;
    }

    &:hover {
      .wechat-qrcode {
        display: inline-block;
      }
    }
  }

  .icon {
    height: 2em;
  }
}
</style>
