<template>
  <time :datetime="utc" v-b-tooltip :title="tooltip">{{prefix + formatted + suffix}}</time>
</template>

<script>
export default {
  name: 'datetime',
  props: {
    value: {
      type: [String, Number, Date]
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    full: Boolean,
    beijing: Boolean,
    format: String
  },
  computed: {
    utc() {
      return this.$day(this.value).toJSON()
    },
    tooltip() {
      if (this.format) {
        return ''
      }
      return this.$day(this.value).format('L HH:mm:ss')
    },
    formatted() {
      let day = this.$day(this.value)
      if (this.full) {
        return day.utcOffset(8).format('L LT')
      }
      if (this.beijing) {
        day = day.utcOffset(8)
      }
      if (this.format) {
        return day.format(this.format)
      }
      return day.calendar()
    }
  }
}
</script>

<style scoped>

</style>
