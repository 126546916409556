<template>
  <transition name="fade">
    <div :class="{'d-sm-none': !sm, 'nav': nav}" class="bottom" v-if="show">
      <div class="container">
        <slot name="prepend"></slot>
        <slot>
          <b-navbar>
            <b-navbar-nav justified>
              <slot name="navs">
                <template v-if="items">
                  <b-nav-item @click="item.onClick || null" :key="item.text"
                              v-bind="props(item)"
                              v-on="events(item)"
                              v-for="item in navItems">
                    <div class="tooltip bs-tooltip-top b-tooltip-primary"
                         v-if="item.desc && !hasViewed(item)"
                         @click.stop="hideTooltip(item)">
                      <div class="tooltip-inner">{{item.desc}}</div>
                      <div class="arrow" style="left: 50%; margin-left: -8px;"></div>
                    </div>
                    <fa :icon="item.icon" class="icon" v-if="item.icon"/>
                    <avatar class="icon" size="18px" :src="item.image" v-if="item.image"/>
                    <span :class="{small: item.icon || item.image}">{{item.text || item.title}}</span>
                    <span class="dot" v-if="item.dotted"></span>
                    <b-badge variant="primary" v-else-if="item.tag" class="tag">{{item.tag}}</b-badge>
                  </b-nav-item>
                </template>
              </slot>
            </b-navbar-nav>
          </b-navbar>
        </slot>
        <slot name="append"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'bottomBar',
  props: {
    sm: Boolean,
    nav: Boolean,
    items: Array,
    target: String
  },
  data() {
    return {
      show: true
    }
  },
  computed: {
    navItems() {
      return this.items.filter(i => i.show !== false && i.exclusive !== 'menu')
    }
  },
  beforeDestroy() {
    this.observer && this.observer.disconnect()
  },
  created() {
    if (!this.target) {
      return
    }
    this.show = false
    setTimeout(() => {
      if (!('IntersectionObserver' in window)) {
        this.show = true
        return
      }

      this.observer = new IntersectionObserver(entries => {
        const entry = entries[entries.length - 1]
        if (!entry) {
          return
        }
        this.show = !(entry.isIntersecting || entry.intersectionRatio > 0)
      })

      const el = document.querySelector(this.target)
      if (!el) {
        return
      }

      this.observer.observe(el)
    })
  },
  methods: {
    hasViewed(item) {
      return this.$ss.get(['tooltip', this.$route.name, item.title].join('.'))
    },
    hideTooltip(item) {
      this.$ss.set(['tooltip', this.$route.name, item.title].join('.'), 1)
      this.$set(item, 'tooltip', '')
      this.$forceUpdate()
    },
    events(item) {
      if (item.onClick) {
        return {
          click: item.onClick
        }
      }
    },
    props(item) {
      const bind = {
        class: []
      }

      if (item.variant) {
        bind.class.push(item.variant)
      }

      if (item.divide) {
        bind.class.push('divide')
      }

      if (item.maxWidth) {
        bind.style = {
          'max-width': item.maxWidth
        }
      }

      if (item.disabled) {
        bind.disabled = true
      }

      if (item.replace) {
        bind.replace = true
      }

      if (item.style) {
        bind.style = Object.assign({}, bind.style, item.style)
      }

      if (item.active) {
        bind.active = true
      }

      if (item.show === false) {
        bind.hidden = true
      }

      if (item.href) {
        bind.href = item.href
        if (item.target) {
          bind.target = item.target
        }
      }

      if (item.to) {
        bind.to = item.to
        if (item.to === '/' || item.exact) {
          bind.exact = true
        }
      }

      return bind
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: $shadow-base;
  border-top: 1px solid $hr-border-color;
  transition: .3s;
  z-index: 20;

  &.nav {
    height: $navbar-height;

    .container {
      max-width: 800px;
      padding: 0;
    }

    @include iPhoneX {
      height: $navbar-height + $safe-area-bottom;
    }
  }

  @include iPhoneX {
    padding-bottom: $safe-area-bottom !important;
  }

  ::v-deep {
    .container {
      padding: 0;
    }

    .navbar {
      padding: 5px;
      height: 100%;
    }

    .navbar-nav {
      align-items: center;
      flex-direction: row;
      line-height: 1.2;
      width: 100%;
    }

    .divider {
      padding: .5rem 0;
      width: 1px;
      position: relative;
      height: 3.5rem;
      margin: 0 2px;

      &:after {
        content: '';
        display: block;
        height: 100%;
        border-left: 1px solid $hr-border-color;
      }
    }

    .nav-item {
      white-space: nowrap;
      position: relative;

      &.divide {
        position: relative;

        &:after {
          display: block;
          content: '';
          position: absolute;
          top: 20%;
          bottom: 20%;
          right: 0;
          border-right: 1px solid $hr-border-color;
        }
      }

      &.primary, &.warning, &.success {
        .nav-link {
          color: #fff;
          border-radius: $border-radius-lg;
        }
      }

      &.primary .nav-link {
        background-color: $primary;
      }

      &.success .nav-link {
        background-color: $success;
      }

      &.warning .nav-link {
        background-color: $warning;
      }
    }

    .nav-link {
      &.disabled {
        pointer-events: none;
        opacity: .65;
      }

      &.active {
        color: $primary;

        .avatar {
          border-color: $primary;
        }

        i {
          font-weight: 600;
        }
      }
    }

    .tooltip {
      bottom: 100%;
      margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      min-width: 100%;

      .tooltip-inner {
        max-width: none;
        white-space: nowrap;
      }
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 10px;
      background-color: $primary;
      position: absolute;
      top: 2px;
      right: 50%;
      margin-right: -2.5em;
    }

    .icon {
      display: block;
      margin-bottom: .25em;
      line-height: 1.25;
      font-size: 1rem;
    }
  }

  .avatar {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100px;
  }

  .tag {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
