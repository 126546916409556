/**
 * Created by chenzhuokai on 2016/10/11.
 */
export default {
  data() {
    return {
      isDev: window.isDev,
      isApp: window.isApp,
      isMobile: window.isMobile,
      isWechat: window.isWechat,
      isAndroid: window.isAndroid,
      isIOS: window.isIOS,
      isTouchDevice: window.isTouchDevice
    }
  }
}
