import Vue from 'vue'

Vue.directive('dimmer', {
  inserted(el, binding, vNode) {
    const dimmer = document.createElement('div')
    dimmer.className = 'dimmer'
    dimmer.style.backgroundColor = 'rgba(0,0,0,0.5)'
    dimmer.style.position = 'fixed'
    dimmer.style.zIndex = window.getComputedStyle(el).zIndex - 1
    dimmer.style.top = 0
    dimmer.style.left = 0
    dimmer.style.width = '100%'
    dimmer.style.height = '100%'
    dimmer.onclick = () => {
      vNode.context.$emit('close')
    }
    document.body.appendChild(dimmer)
    el.dimmer = dimmer
  },
  unbind(el) {
    el.dimmer && el.dimmer.remove()
  }
})
