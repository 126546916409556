<template>
  <div class="popup" @click="backdropClose">
    <div class="container">
      <div class="content">
        <b-card v-if="card">
          <slot></slot>
        </b-card>
        <template v-else>
          <slot>
            <template v-if="src">
              <div v-if="status === 1">
                <fa icon="spinner-third" spin/>
                <div>正在加载海报</div>
              </div>
              <div v-else-if="status === -1">
                <fa icon="exclamation-circle"/>
                <div>海报加载错误</div>
              </div>
              <img class="poster" draggable="false" :src="src" v-else-if="status === 2">
            </template>
          </slot>
        </template>
        <transition name="fade-in-down">
          <div class="save-guide text-center" v-if="guide && showGuide">
            <div class="pill">
              <div class="mb-1" style="font-size: 1.25em;">{{isMobile ? '长按此处' : '右击此处'}}</div>
              {{guide}}
            </div>
          </div>
        </transition>
      </div>
      <div class="tip py-2" v-if="tip">{{tip}}</div>
      <footer v-if="!keep && !hideClose">
        <b-btn pill variant="white" class="btn-close" @click="close">关闭</b-btn>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'popup',
  props: {
    src: String,
    tip: String,
    size: String,
    keep: Boolean,
    card: Boolean,
    guide: String,
    hideClose: Boolean,
    noBackdropClose: Boolean
  },
  data() {
    return {
      showGuide: false,
      status: 0,
      imgWidth: 0,
      imgHeight: 0
    }
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open')
    this.$el.classList.remove('active')
    this.$el.classList.remove('leaving')
  },
  mounted() {
    document.body.append(this.$el)
    document.body.classList.add('modal-open')
    setTimeout(() => {
      this.init()
      this.$el.classList.add('active')
    })
  },
  computed: {
    imgStyle() {
      if (!this.imgWidth || !this.imgHeight) {
        return null
      }
      return {
        width: this.imgWidth,
        height: this.imgHeight
      }
    }
  },
  methods: {
    init() {
      if (!this.src) {
        return
      }
      this.status = 1
      this.loadImage(this.src).then(() => {
        this.status = 2
        if (this.guide) {
          setTimeout(() => {
            this.showGuide = true
          }, 1000)

          setTimeout(() => {
            this.showGuide = false
          }, 5000)
        }
      }).catch(() => {
        this.status = -1
      })
    },
    backdropClose() {
      if (!this.noBackdropClose) {
        this.close()
      }
    },
    close() {
      if (this.keep) {
        return
      }
      this.$el.classList.add('leaving')
      setTimeout(() => {
        this.$emit('close')
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  z-index: 30;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: opacity .3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.leaving {
    opacity: 0;
  }

  .card {
    color: $body-color;
    width: 100%;
    max-width: 360px;
  }

  .container {
    max-width: 560px;
    height: 100%;
    max-height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex-basis: 100%;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img.poster {
    max-width: 100%;
    max-height: 100%;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  footer {
    width: 100%;
    padding: 1rem;
    text-align: center;
    @include iPhoneX {
      padding-bottom: $safe-area-bottom * 2;
    }
  }

  .btn-close {
    border: 1px solid #fff;
    color: #fff;
    width: 6em;
    background-color: rgba(0, 0, 0, .2);

    &:hover {
      opacity: .8;
    }
  }

  .tip {
    color: #fff;
    text-align: center;
  }

  .save-guide {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -8rem;
    z-index: 1;
    text-align: center;

    .pill {
      display: block;
      border-radius: 8px;
      padding: 1rem 2rem;
      font-size: 1rem;
      background-color: transparentize(#fff, 0.1);
      color: #000;
      line-height: 1.2;
      box-shadow: $box-shadow;
    }
  }
}
</style>
