<template>
  <main>
    <div style="display: none;" v-if="isWechat">
      <img src="https://static.weixinshu.com/assets/images/logo.png" alt="微信分享默认缩略图">
    </div>
    <browser-notification v-if="deprecated"/>
    <template v-else>
      <navbar v-if="!isPartnerUser"/>
      <div class="wrapper">
        <error-view class="container" v-if="error" :value="error"/>
        <router-view class="container" v-else/>
      </div>

      <template v-if="!isPartnerUser && $route.name">
        <large-footer v-if="$route.name === 'home'"/>
        <simple-footer v-else/>
      </template>
      <side-buttons v-if="!isPartnerUser"/>
    </template>
  </main>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'

import Navbar from './components/Navbar'
import LargeFooter from './components/LargeFooter'
import SimpleFooter from './components/SimpleFooter'
import SideButtons from './components/SideBtns'
import ErrorView from './pages/Error'
import BrowserNotification from './components/BrowserNotification'
import { until } from '@/utils/wait-for'

export default {
  name: 'app',
  components: {
    BrowserNotification,
    ErrorView,
    Navbar,
    LargeFooter,
    SimpleFooter,
    SideButtons
  },
  data() {
    return {
      deprecated: false
    }
  },
  computed: {
    ...mapGetters(['error'])
  },
  created() {
    if (/fullscreen/.test(location.search)) {
      document.body.setAttribute('fullscreen', 'fullscreen')
    }

    if (!this.token) {
      this.$store.commit('resetAuth')
    }

    if (this.user?.xinshuUserId) {
      const {xinshuUserId, nickname} = this.user
      this.$gtag.config({user_id: xinshuUserId})
      until(() => window?.__bl?.setConfig).then(() => {
        window.__bl.setConfig({
          uid: xinshuUserId,
          setUsername: () => nickname || null
        })
      })
    }

    if (window.isDev) {
      document.body.classList.add('dev')
    }

    if (/(iPhone|iPad) OS [6789]_\d/i.test(navigator.userAgent)) {
      this.deprecated = true
    }

    this.onresize = debounce(() => {
      console.log('Resize')
      this.$store.commit('setWinSize', [window.innerWidth, window.innerHeight])
    }, 500)

    window.addEventListener('resize', this.onresize)
  },
  watch: {
    $route() {
      this.$clearError()
    }
  },
  beforeDestroy() {
    document.removeEventListener('resize', this.onresize)
  }
}
</script>

<style lang="scss">
@import './bootstrap';
@import '~bootstrap-vue/src/index';
@import 'styles/alert';
@import 'styles/base';
@import 'styles/layout';
@import 'styles/typeset';
@import 'styles/animation';
@import 'styles/utilities';
@import 'styles/marker';
@import 'styles/fixes';
@import 'styles/buttons';
@import 'styles/word-count';
@import 'styles/nav-tabs';
@import 'styles/card';

@import '~swiper/swiper-bundle.css';

:root {
  --swiper-theme-color: #{$primary};
  --swiper-navigation-color: #{$primary};
}
</style>
