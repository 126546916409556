/**
 * Created by chenzhuokai on 16/5/11.
 */
import { get, includes, isBoolean, isNaN, isString, isUndefined } from 'lodash'
import { state } from '@/vuex/store'
import { imgHost } from '@/config'

export function serializeToFormString(params, addQuestionMark = true, doSnakeCase) {
  if (!isObject(params)) {
    return ''
  }

  doSnakeCase = doSnakeCase !== false
  let output = ''
  Object.keys(params).forEach((key) => {
    let val = params[key]

    if (isUndefined(val) || isNaN(val)) {
      return
    }

    if (isString(val) && !val.length) {
      return
    }

    if (isObject(params[key])) {
      val = JSON.stringify(val)
    }

    if (isBoolean(val)) {
      val = val ? 1 : 0
    }

    if (doSnakeCase) {
      key = snakeCase(key)
    }

    output += key + '=' + encodeURIComponent(val).replace(/['()*"]/g, escape) + '&'
  })
  output = output.replace(/&$/, '')
  if (!output) {
    return ''
  }
  output = (addQuestionMark ? '?' : '&') + output

  return output
}

function snakeCase(string) {
  return string.replace(/[A-Z]/g, $1 => '_' + $1.toLowerCase())
}

export function isObject(val) {
  if (val === null) {
    return false
  }
  return typeof val === 'function' || typeof val === 'object'
}

export function extend(a, b) {
  // Don't touch 'null' or 'undefined' objects.
  if (a == null || b == null) {
    return a
  }

  Object.keys(b).forEach(function (key) {
    if (Object.prototype.toString.call(b[key]) === '[object Object]') {
      if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
        a[key] = b[key]
      } else {
        a[key] = extend(a[key], b[key])
      }
    } else {
      a[key] = b[key]
    }
  })

  return a
}

export function doUntil(method, condition, interval, cb) {
  let timeout = null
  startLoop()
  return () => clearTimeout(timeout)

  function startLoop() {
    if (!method || !condition) {
      return
    }
    clearTimeout(timeout)
    method().then(res => {
      if (condition(res)) {
        (typeof cb === 'function') && cb()
        return
      }
      timeout = setTimeout(startLoop, interval)
    })
  }
}

export function getAuthUrl(url) {
  const redirectUrl = `https://weixinshu.com/weixin_auth/gh_1fd5050837d5?redirect_url=${encodeURIComponent(
    url)}`
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8122e5ac082c0978&redirect_uri=${encodeURIComponent(
    redirectUrl)}&response_type=code&scope=snsapi_userinfo&connect_redirect=1#wechat_redirect`
}

export const base64Url = {
  encode(str) {
    return btoa(unescape(encodeURIComponent(str))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  },
  decode(str) {
    str = (str + '==='.slice((str.length + 3) % 4)).replace(/-/g, '+').replace(/_/g, '/')
    return decodeURIComponent(escape(atob(str)))
  }
}

window.base64 = base64Url

export function proxyUrl(src, size = 960) {
  if (/\/fetch|canvas\.xinshu\.me|^(blob|data|weixin)|localhost/.test(src)) {
    return src
  }

  if (/cgi-bin\/showqrcode/.test(src)) {
    return src
  }

  if (!src) {
    return ''
  }

  const originalUrl = src

  if (/tc\.qq.+(mp4$|snsdownload)/.test(src)) {
    return 'https://canvas.xinshu.me/generate/wxbook-video?url=' + encodeURIComponent(originalUrl)
  }

  const suffix = size ? '!' + (window.canUseWebp ? 'webp' : '') + size : ''
  const isSelfHosted = /^\/(media|upload|fetch)|(img|static|cdn)\.xinshu\.me/.test(src)

  if (isSelfHosted) {
    return src.split(/[#?!]/)[0].replace(/.*\/(upload|fetch)/, imgHost + '/$1') + suffix
  }

  if (/^http/.test(src) && !/xinshu\.me|weixinshu\.com/.test(src)) {
    return 'https://media2.xinshu.me/fetch/' + base64Url.encode(originalUrl) + suffix
  }

  return src
}

/**
 * @typedef Dimension
 * @property {Number} w - width of image
 * @property {Number} h - height of image
 * get image dimension
 * @param src
 * @return {Promise<Dimension>}
 */
export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const w = img.naturalWidth
      const h = img.naturalHeight
      resolve({w, h})
    }
    img.onerror = reject
    img.src = src
  })
}

export function setTitle(title) {
  const userSource = state.source
  const suffix = window.isMobile ? '' : '心书·微信书'
  if (isString(title) && !includes(title, suffix) && !userSource) {
    title = [title, suffix].filter(Boolean).join(' - ')
  }
  if (window.isDev) {
    title = '[Dev] ' + title
  }
  document.title = title
}

export function getKey(key) {
  return data => get(data, key) || data
}

export function composedPath(event) {
  const path = event.path || (event.composedPath && event.composedPath()) || []
  let el = event.target

  if (path.length) {
    return path
  }

  while (el) {
    path.push(el)

    if (el.tagName === 'HTML') {
      path.push(document)
      path.push(window)

      return path
    }

    el = el.parentElement
  }

  return path
}

export function isVerGreaterThan(target, src) {
  const tMajor = target.split('.')[0]
  const tMinor = target.split('.')[1]
  const tPatch = target.split('.')[2]

  const sMajor = src.split('.')[0]
  const sMinor = src.split('.')[1]
  const sPatch = src.split('.')[2]

  if (tMajor > sMajor) {
    return true
  }

  if (tMinor > sMinor) {
    return true
  }

  return tPatch > sPatch
}
