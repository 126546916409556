import * as ajax from '@/ajax'

export function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  Vue.ajax = ajax
  Object.defineProperties(Vue.prototype, {
    $ajax: {
      get() {
        return Vue.ajax
      }
    }
  })
}
