<template>
  <div class="loading" :class="type">
    <div class="loading-container" @click="init">
      <fa v-bind="faProps" fad size="3x" color="primary"/>
      <div class="mt-2" v-if="text">{{text}}</div>
    </div>

    <div class="mask" v-if="type === 'toast'"></div>
  </div>
</template>

<script>
import { sample } from 'lodash'

export default {
  name: 'loader',
  props: {
    text: {
      type: String,
      default: '加载中...'
    },
    type: {
      default: 'inline',
      type: String
    }
  },
  data() {
    return {
      faProps: null,
      selected: 0,
      options: [
        {icon: 'spinner-third', class: 'fa-spin'},
        {icon: 'camera', class: 'fa-flip'},
        {icon: 'heart', class: 'fa-beat'},
        {icon: 'shower', class: 'fa-fade'},
        {icon: 'alien', class: 'fa-bounce'},
        {icon: 'books', class: 'fa-beat'},
        {icon: 'alien-8bit', class: 'fa-beat-fade'},
        {icon: 'basketball', class: 'fa-bounce'},
        {icon: 'frog', class: 'fa-bounce'},
        {icon: 'compact-disc', class: 'fa-flip'},
        {icon: 'aperture', class: 'fa-spin'},
        {icon: 'battery-bolt', class: 'fa-beat-fade'}
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.faProps = sample(this.options)
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  text-align: center;
  margin: 1em auto;
  color: #999;

  .mask {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10vh;
    background-color: #fff;
    z-index: 10;

    .loader > div {
      background-color: $text-muted;
    }
  }

  &.toast {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    .loading-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: rgba(0, 0, 0, .6);
      border-radius: 15%;
      width: 160px;
      height: 160px;
      color: #fff;
      padding: 50px 0;
      z-index: 100;
    }

    .loader > div {
      height: 40px;
    }
  }

  &.inline {
    width: 100%;
    color: #999;

    .loader {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;

      > div {
        margin-left: 1px;
        margin-right: 1px;
        width: 2px;
        height: 1em;
        background-color: $text-muted;
      }
    }

    p {
      vertical-align: middle;
      display: inline-block;
    }
  }

  p {
    margin: 0;
  }
}
</style>
