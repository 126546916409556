import Vue from 'vue'
import _ from 'lodash'
import {
  albumProducts,
  bindings,
  bookProducts,
  bookTypes,
  shipCompanies,
  statuses,
  studioProducts,
  wmProducts
} from './config'

const products = {...albumProducts, ...bookProducts, ...wmProducts, ...studioProducts}

Vue.filter('truncate', (val, len) => {
  return _.truncate(val, {length: len})
})

Vue.filter('percent', (val, round = 1) => {
  return _.round(val * 100, round) + '%'
})

Vue.filter('productName', (val, short) => {
  return _.get(products, [val, short ? 0 : 1]) || val
})

Vue.filter('binding', (val) => {
  return bindings[val] || val
})

Vue.filter('shipCompany', val => {
  return shipCompanies[val] || val
})

Vue.filter('bookType', (val) => {
  val = val.replace('a5', 'A5')
  return bookTypes[val] || val
})

Vue.filter('orderStatus', (val) => {
  return statuses[val] || val
})

Vue.filter('round', (val, decimal = 2) => {
  return _.round(val, decimal)
})

Vue.filter('couponValue', (val) => {
  if (val >= 1) {
    val = _.round(val, 2)
    return `${val}元`
  }
  return _.round(val * 10, 2) + '折'
})

Vue.filter('rmb', (num, operator) => {
  if (!num && num !== 0) {
    return num
  }
  if (/￥/.test(num)) {
    return num
  }
  const isNegative = num < 0
  let prefix = '￥'
  if (isNegative) {
    prefix = '￥-'
  } else if (operator) {
    prefix = '￥+'
  }
  num = Math.abs(Number(num)).toFixed(2)
  return prefix + num.replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,')
})

Vue.filter('showBindings', (binding) => {
  let msg = '全部装帧'
  if (binding && binding.length > 0) {
    msg = '限' + _.chain(binding).map(item => bindings[item]).uniq().filter(Boolean).join('、')
  }
  return msg
})

Vue.filter('showPublishTypes', (binding) => {
  let msg = '全部排版'
  if (binding && binding.length > 0) {
    msg = '限 ' + _.chain(binding).map(item => bookTypes[item]).uniq().filter(Boolean).join('、')
  }
  return msg
})

Vue.filter('showProducts', (value) => {
  let msg = '全部产品'
  if (value && value.length > 0) {
    msg = '限' + _.chain(value)
      .map(item => _.get(products[item], [1]) || item)
      .uniq()
      .filter(Boolean)
      .join('、')
  }
  return msg
})

Vue.filter('discount', (val) => {
  return val >= 10 ? '原价' : (_.round(val, 2) + ' 折')
})
