/**
 * Created by kk on 17/7/18.
 */
import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

import mutations from './mutations'
import * as getters from './getters'

Vue.use(Vuex)

const tmpToken = location.search.match(/tmptoken=(\w+)/) ? location.search.match(/tmptoken=(\w+)/)[1] : ''
const user = JSON.parse(Cookies.get('wxbook.user') || null)

if (user) {
  user.avatar = user.headimgurl || user.avatar
  delete user.headimgurl
}

export const state = {
  user: user || {},
  token: tmpToken || Cookies.get('wxbook.token') || '',
  winSize: [window.innerWidth, window.innerHeight],
  error: null,
  source: sessionStorage.getItem('source'),
  prevRoute: null,
  routeHistory: []
}

const store = new Vuex.Store({
  state,
  mutations,
  getters
})

export default store
