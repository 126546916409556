import { chain, pick } from 'lodash'

import request from '@/plugins/request'
import { extendBook, decodeBookParams, isAlbum } from './book'
import { extendAlbum } from './album'
import { serializeToFormString } from '@/utils/utils'

export function fetchOrders({page = 1, pageSize, isWm, keyword, binding} = {}) {
  return request.get('/api/order/orders', {
    params: {page, pageSize, name: keyword, binding}
  }).then(orders => {
    const totalCount = orders.totalCount
    orders = chain(orders)
      .filter(i => isWm ? i.bookType.startsWith('wm-') : !i.bookType.startsWith('wm-'))
      .orderBy(['createTime', 'id'], ['desc', 'desc'])
      .map(extendOrder)
      .value()
    orders.totalCount = totalCount
    return orders
  })
}

export function fetchWeimengOrders({page, pageSize}) {
  return fetchOrders({page, pageSize, isWm: true})
}

export function fetchDivisions({orderNo}) {
  return request.get('/api/order/' + orderNo + '/printbooks/pdfs').then(results => {
    return Promise.all(results.map(async item => {
      const {printbookId: bookId, pdfType: section} = item
      const result = await request.get(`/api/order/${orderNo}/printbooks/${bookId}/pdf_url`, {
        params: {
          pdf_type: section
        }
      })
      result.url = result.url
        .replace('http://pdf-archive.oss-cn-hangzhou.aliyuncs.com', 'https://pdf.weixinshu.com')
      return {...item, ...result}
    }))
  })
}

export function fetchRechargeOrder({orderNo}) {
  return request.get(`/api/gift_card/order/${orderNo}`)
}

export function fetchOrder({orderNo}) {
  if (/^giftcard/.test(orderNo) || /^2\d{14}$/.test(orderNo)) {
    orderNo = orderNo.replace('giftcard', '')
    return request.get(`/api/gift_card/order/${orderNo}`)
  }
  if (/^weimeng/.test(orderNo) || /^3\d{14}$/.test(orderNo)) {
    orderNo = orderNo.replace('weimeng', '')
    return request.get(`/api/weimeng/orders/${orderNo}`)
  }
  orderNo = orderNo.replace(/^[a-zA-Z]+/, '')
  return request.get('/api/order/' + orderNo).then(extendOrder)
}

export function fetchOrderStatus({orderNo}) {
  if (/^giftcard/.test(orderNo) || /^2\d{14}$/.test(orderNo)) {
    orderNo = orderNo.replace('giftcard', '')
    return request.get(`/api/gift_card/order/${orderNo}/status`)
  }
  return request.get('/api/order/' + orderNo + '/status')
}

export function fetchPrice({bookId, count = 1, isAgent, ...params}) {
  const {id, bookType} = decodeBookParams(bookId)
  params = pick(params, ['payType', 'address', 'couponNo', 'delivery', 'binding'])
  let prefix = '/api/order/price/'
  if (isAgent) {
    // 代理用户直接下单
    prefix += 'agent/'
  }
  let url = prefix + id + serializeToFormString({...params, count, bookType})
  if (isAlbum(bookType) || bookType === 'blogbook' || bookType === 'star-wbbook') {
    // 照片书或明星微博书或博客书
    url = '/api/order/albums/' + id + '/price' +
      serializeToFormString({...params, count, tid: bookType})
    if (isAgent) {
      return Promise.resolve({oriPrice: null})
    }
  }
  return request.get(url).then(result => {
    if (result.details) {
      result.details = result.details.map(item => {
        if (item.type !== 'delivery_fee') {
          item.value = Math.abs(item.value) * -1
        }
        return item
      })
    }
    return result
  })
}

export function fetchPackage({id}) {
  return request.get(`/api/activity/packages/${id}`)
}

export function fetchOrderCodes({orderNo}) {
  return request.get(`/api/order/packages/orders/${orderNo}/coupons`)
}
export function fetchPackageOrders() {
  return request.get('/api/order/packages/orders')
}

export function extendOrder(order) {
  if (order.bookType === 'xsalbum-package') {
    order.book = {
      tid: 'xsalbum-package',
      name: '皮面册礼品卡'
    }
  }

  if (order.book) {
    if (order.book.tid) {
      order.book = extendAlbum(order.book)
    } else {
      order.book = extendBook(order.book)
    }
    order.book.title = order.bookName || order.book.title || order.name
  }

  if (order.bookType === 'xsalbum-package') {
    order.book.tid = 'xsalbum-package'
    order.book.cover = {
      codeName: 'cover-xsalbum-package',
      subtitle: order.id
    }
  }

  order.hasCashback = order.cashBackStatus !== undefined
  order.hasRepair = order.repairStatus < 5

  return order
}
