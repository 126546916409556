<template>
  <b-link :href="href" :to="to" class="cell cell-link" :class="{reverse, disabled}"
          :target="target" @click="$emit('click')">
    <b-row align-v="center">
      <b-col cols="auto" class="pr-0" v-if="$slots.head || $slots.icon || icon">
        <slot name="head"></slot>
        <slot name="icon" v-if="$slots.icon"></slot>
        <fa :icon="icon" fw v-else-if="icon"/>
      </b-col>
      <b-col class="cell-body">
        <slot v-if="$slots.default"></slot>
        <slot name="body" v-else-if="$slots.body"></slot>
        <template v-else>{{title}}</template>
        <slot name="badge"></slot>
      </b-col>
      <b-col cols="auto">
        <slot name="tail" v-if="$slots.tail"></slot>
        <fa icon="chevron-right" v-else-if="to || href"/>
      </b-col>
    </b-row>
  </b-link>
</template>

<script>
export default {
  name: 'cellLink',
  props: {
    title: String,
    disabled: Boolean,
    reverse: Boolean,
    headWidth: {
      type: String,
      default: '2.5rem'
    },
    icon: String,
    href: String,
    to: String,
    target: String
  }
}
</script>

<style scoped lang="scss">
  .cell-link {
    outline: none;
    display: block;
    padding: 1rem 1.5rem;
    color: #000;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid $hr-border-color;
    &:last-child {
      border-bottom-color: transparent;
    }
    &.disabled {
      cursor: not-allowed;
    }
    &.reverse > .row {
      flex-direction: row-reverse;
    }
    &:not(.reverse) {
      .cell-head {
        padding-right: 0;
      }
    }
  }
</style>
