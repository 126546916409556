<template>
  <div class="select-group">
    <grid :cols="cols" :sm="sm">
      <grid-item :key="item.value" class="select-item" v-for="item in selectItems">
        <button
          :disabled="item.disabled || disabled"
          :class="[{active: choice === item.value, 'btn-block mb-2': block}, 'size-' + size, 'text-' + align, item.variant ? 'btn-' + item.variant : '']"
          @mouseover="showPreview(item)"
          @mouseout="hidePreview"
          @click="choice = item.value"
          class="btn btn-select"
          type="button">
          <template v-if="item.icon">
            <img :src="item.icon" class="icon" alt="" v-if="/^http|^\//.test(item.icon)">
            <fa fw :icon="item.icon" v-else/>
          </template>
          {{item.title}}

          <span class="tag" v-if="item.tag">{{item.tag}}</span>
          <span class="check" v-if="choice === item.value">
            <fa icon="check"/>
          </span>
        </button>
      </grid-item>
    </grid>
    <div class="preview" @click.stop.prevent="preview = null" v-if="preview">
      <img :src="preview"/>
    </div>
  </div>
</template>

<script>
import { isArray, isPlainObject, map } from 'lodash'

export default {
  name: 'selectGroup',
  props: {
    options: {
      type: [Array, Object],
      required: true
    },
    value: {
      required: true
    },
    cols: {
      default: 'auto',
      type: [String, Number]
    },
    sm: {
      default: 'auto',
      type: [String, Number]
    },
    size: {
      default: 'md',
      type: String
    },
    align: {
      type: String,
      default: 'center'
    },
    disabled: Boolean,
    block: Boolean
  },
  data() {
    return {
      preview: false,
      choice: this.value
    }
  },
  computed: {
    selectItems() {
      if (isArray(this.options)) {
        return this.options
      }
      if (isPlainObject(this.options)) {
        return map(this.options, (val, key) => {
          return {title: val, value: key}
        })
      }
      return []
    }
  },
  watch: {
    choice(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    },
    value(val) {
      this.choice = val
    }
  },
  methods: {
    showPreview(item) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.preview = item.preview
      }, 250)
    },
    hidePreview() {
      clearTimeout(this.timeout)
      setTimeout(() => {
        this.preview = null
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
  .select-group {
    position: relative;
    margin-top: -$grid-gutter-width-base / 4;
    margin-bottom: -$grid-gutter-width-base / 4;

    .preview {
      position: absolute;
      top: 0;
      right: 100%;
      margin-right: 1rem;
      width: 480px;
      z-index: 10;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid $hr-border-color;
      overflow: hidden;
      box-shadow: $box-shadow;
      @include media-breakpoint-down(sm) {
        top: auto;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 100%;
        margin-bottom: 1rem;
        margin-right: 0;
      }

      img {
        width: 100%;
      }
    }

    .icon {
      height: 1.25em;
      vertical-align: sub;
      width: 1.25em;
    }

    .tag {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $primary;
      color: #fff;
      border-bottom-left-radius: $border-radius;
      line-height: 1;
      padding: 2px 5px;
      font-size: 20px;
      transform: scale(.5);
      transform-origin: top right;
    }

    .select-item {
      white-space: nowrap;
    }

    .check {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $white;
      font-size: 12px;
      line-height: 1;
      padding: 1px;
      padding-left: 12px;
      padding-top: 12px;
      background-color: $primary;
      background: linear-gradient(135deg, transparent 18px, $primary 0);
      transform-origin: bottom right;
      transform: scale(.75);
    }

    .btn {
      border: 1px solid $input-border-color;
      padding-left: 1em;
      padding-right: 1em;
      position: relative;
      width: 100%;
      background-color: #fff;

      &.size-lg {
        padding: $btn-padding-y * 2 $btn-padding-x-lg;
      }

      &:not(.active) {
        img.icon {
          filter: grayscale(1);
        }
      }

      &.active {
        color: $primary;
        border-color: currentColor;
        box-shadow: 0 0 1px 0 currentColor;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
