<template>
  <div class="error">
    <div>
      <img src="https://static.weixinshu.com/assets/images/deer/cry.png" style="width: 240px; max-width: 100%;">
    </div>
    <h2>哎呀出错啦</h2>
    <p>页面发生了一些错误</p>
    <div class="alert alert-primary error-message" v-if="error.message">
      {{error.message}}
      <template v-if="error.code">({{error.code}})</template>
    </div>

    <div class="btn-area" v-if="error.to">
      <b-btn :to="error.to" block variant="primary">重新登录</b-btn>
    </div>

    <div v-else>
      <b-btn @click="$clearError()">重试</b-btn>
      <b-btn @click="$router.go(-1)" variant="primary">返回</b-btn>
    </div>

    <footer v-if="!isMiniProgram">
      <qiyu variant="link"/>
    </footer>
  </div>
</template>

<script>
import Qiyu from '@/components/Qiyu'

export default {
  name: 'errorPanel',
  components: {Qiyu},
  props: ['value'],
  computed: {
    error() {
      return this.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .error {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 60px;

    footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: 1rem;
    }

    .error-message {
      padding: 1em;
      margin: 1em auto;
    }

    .fa {
      font-size: 5em;
      margin-bottom: 1rem;
    }
  }
</style>
