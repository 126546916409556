import Vue from 'vue'

Vue.directive('bottomLoad', {
  inserted(el, binding, vNode) {
    if (el.classList.contains('has-load')) {
      return
    }
    el.onDocumentScroll = (e) => {
      const isMoreScreen = window.pageYOffset > 100
      if (isMoreScreen) {
        vNode.context.active = 'navbar'
      } else {
        vNode.context.active = 'tips'
      }
    }
    document.addEventListener('scroll', el.onDocumentScroll, false)
  },
  unbind(el) {
    document.removeEventListener('scroll', el.onDocumentScroll)
  }
})
