import dayjs from 'dayjs'
import { sumBy } from 'lodash'

import request from '@/plugins/request'
import { getBookId } from '@/models/book'
import { bookTypes } from '@/config'

// 搜索外链文章
export function searchArticle({url}) {
  return request.post('/blogbook/crawler/search_article/', {
    url
  })
}

// 当前可用的小编
export function fetchAssistant() {
  return request.get('/blogbook/crawler/assistants/')
}

// 小编详情
export function fetchAssistantDetail({id}) {
  return request.get(`/blogbook/crawler/assistants/${id}/`)
}

// 搜索导入源
export function fetchSource({sourceSite, sourceId}) {
  return request.get('/blogbook/crawler/search/', {
    params: {
      source_site: sourceSite,
      source_id: sourceId
    }
  })
}

// 导入
export function importSource(params) {
  return request.post('/blogbook/crawler/crawl/', params)
}

// 创建书籍
export function createBlogbook(params) {
  return request.post('/blogbook/books/', params).then(extendBlogbook)
}

// 获取书籍列表
export function fetchBlogbooks({page = 1, size = 12}) {
  return request.get('/blogbook/books/', {
    params: {
      page,
      page_size: size
    }
  }).then(data => {
    const totalCount = data.totalCount
    data = data.map(item => {
      item = extendBlogbook(item)
      return item
    })
    data.totalCount = totalCount
    return data
  })
}

// 获取某书籍详情
export function fetchBlogbook({bid}) {
  return request.get(`/blogbook/books/${bid}/`, {
    params: {
      ext_fields: 'user'
    }
  }).then(extendBlogbook)
}

// 修改书籍
export function updateBlogbook({bid, data}) {
  return request.post(`/blogbook/books/${bid}/`, {...data}).then(book => {
    extendBlogbook(book)
    return book
  })
}

// 获取某书文章(内容 + 分页)
export function fetchBlogbookArticles({bid, page = 1, size = 12, keyword}) {
  const params = {
    bid,
    page,
    page_size: size,
    fields: 'id,title,author,post_date'
  }
  if (keyword !== '') {
    params.title_keywords = keyword
  }
  return request.get('/blogbook/books/articles/', {
    params
  })
}

// 获取某书已删除的文章(内容 + 分页)
export function fetchBlogbookArticlesRestore({bid, page = 1, size = 12, keyword}) {
  const params = {
    bid,
    page,
    page_size: size,
    deleted: 1,
    fields: 'id,title,author,post_date'
  }
  if (keyword !== '') {
    params.title_keywords = keyword
  }
  return request.get('/blogbook/books/articles/', {
    params
  })
}

// 获取指定文章id的内容
export function fetchArticle({aid}) {
  return request.get(`/blogbook/books/articles/?ids=${aid}`).then(result => result[0])
}

// 某书目录
export async function fetchBlogbookCatalog({bid}) {
  const {catalog, pageCount} = await request.get(`/blogbook/books/${bid}/catalog/`)
  catalog.bookPages = pageCount
  catalog.forEach((item, i) => {
    item.pageNo = sumBy(catalog.slice(0, i), 'pageCount') + 1
  })
  return catalog
}

export function fetchBlogbookCatalogPages({bid}) {
  return request.get(`/blogbook/books/${bid}/catalog/`, {
    params: {
      layout: 1
    }
  }).then(result => {
    return result.layout
      .map(val => ({content: val, pageId: val.pageId}))
      .filter(item => item.content.elements[0].content?.length)
  })
}

// 删除文章
export function deleteBlogbookArticle({bid, aids}) {
  return request.post(`/blogbook/books/${bid}/articles/${aids}/`, {deleted: true})
}

// 新建文章
export function addBlogbookArticle(params) {
  return request.post('/blogbook/books/articles/', params)
}

// 恢复文章
export function restoreBlogbookArticle({bid, aids}) {
  return request.post(`/blogbook/books/${bid}/articles/${aids}/`, {deleted: false})
}

// 修改文章顺序 将某篇文章（move）移动到某篇文章（before）之前
export function changeBlogbookArticleBefore({bid, oldid, newid}) {
  return request.post(`/blogbook/books/${bid}/`, {
    articleOrder: {
      move: oldid,
      before: newid
    }
  })
}

// 修改文章顺序 将某篇文章（move）移动到某篇文章（after）之后
export function changeBlogbookArticleAfter({bid, oldid, newid}) {
  return request.post(`/blogbook/books/${bid}/`, {
    articleOrder: {
      move: oldid,
      after: newid
    }
  })
}

// 某文章的排版
export async function fetchBlogbookLayouts({bid, aid}) {
  const data = await request.get(`/blogbook/books/${bid}/layouts/?aid=${aid}`)
  return data.pages.map((p, index) => {
    p.pageNo = index + 1
    return p
  })
}

export function extendBlogbook(book) {
  book.createTime = book.createdAt
  book.updateTime = book.updatedAt
  book.bookType = 'blogbook'
  book.finalized = book.type === 'finalized'
  book.type = book.typesetType.replace('a4', 'A4').replace('a5', 'A5')
  book.typesetName = bookTypes[book.type] || book.type
  book.pages = book.pageCount
  book.title = book.title || '暂无标题'
  book.auth = book.own ? 'edit' : 'view'
  book.isOwner = !!book.own
  book.editable = !!book.own && !book.finalized
  book.firstMonth = dayjs(book.createTime).add(1, 'day').format('YYYYMM')
  book.bookId = getBookId(book)
  book.canUpdate = book.sourceSite !== 'xinshu' && book.editable
  return book
}
