<template>
  <div class="square" :class="classNames" :data-mode="mode" :style="style" @click="onClick">
    <div class="square-inner" :style="{padding}">
      <template v-if="isPrerender && link">
        <img :src="link" alt class="img prerender">
      </template>
      <template v-else-if="link">
        <fa class="centered" icon="spinner-third" spin v-if="status === 1 && !noLoader"/>
        <fa class="centered fa-lg" icon="exclamation-circle" v-if="status === 3"/>
        <transition name="fade">
          <img :src="link" :class="{disabled: noImg}"
               class="square-image"
               draggable="false" alt v-if="status === 2">
        </transition>
      </template>
      <slot></slot>
      <div class="mask" v-if="$slots.mask || text">
        <slot name="mask">{{text}}</slot>
      </div>
      <slot name="check">
        <div class="check" v-if="selected">
          <span class="check-icon"></span>
        </div>
      </slot>
    </div>
    <div :style="{'padding-bottom': paddingBottom}" class="holder"></div>
  </div>
</template>

<script>
export default {
  name: 'square',
  props: {
    height: {
      type: String,
      default: '100%'
    },
    radius: {
      type: String,
      default: '4px'
    },
    imgSize: [String, Number],
    size: [String, Number],
    src: String,
    noImg: Boolean,
    mode: {
      type: String,
      default: 'cover',
      validator(val) {
        return !val || ['fit', 'contain', 'cover'].includes(val)
      }
    },
    show: Boolean,
    skeleton: Boolean,
    padding: String,
    inline: Boolean,
    text: String,
    border: [Boolean, String],
    plain: Boolean,
    shadow: [Boolean, String],
    noLoader: Boolean,
    selected: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      status: this.show ? 2 : 0,
      isPrerender: /Prerender/i.test(navigator.userAgent),
      loaded: '',
      orientation: '',
      clientWidth: 0,
      clientHeight: 0,
      imageWidth: 0,
      imageHeight: 0
    }
  },
  computed: {
    paddingBottom() {
      if (this.imageWidth && this.imageHeight) {
        return this.imageHeight / this.imageWidth * 100 + '%'
      }

      if (/a5/i.test(this.height)) {
        return 210 / 148 * 100 + '%'
      }

      if (/a4/i.test(this.height)) {
        return 285 / 210 * 100 + '%'
      }

      return this.height
    },
    shadowClass() {
      if (this.plain) {
        return ''
      }
      if (this.shadow) {
        return typeof this.shadow === 'string' ? this.shadow : 'shadow-sm'
      }
      return ''
    },
    style() {
      const style = {}
      let border = this.border
      if (border === true) {
        border = '1px solid rgba(50,50,50,.1)'
      }
      style.border = border
      if (this.radius !== undefined) {
        style.borderRadius = this.radius || '4px'
      }
      if (this.size) {
        const size = String(this.size)
        style.width = /^\d+$/.test(size) ? size + 'px' : size
      }
      return style
    },
    link() {
      const src = this.src
      if (!src) {
        return ''
      }
      const size = this.imgSize || (src.split('!')[1] || '').replace(/\D/g, '')
      return this.$img(src, size)
    },
    clickable() {
      return !!this.$listeners.click
    },
    selectable() {
      return this.$options.propsData.selected !== undefined
    },
    classNames() {
      return [
        {skeleton: this.skeleton},
        {disabled: this.disabled},
        {selectable: this.selectable},
        {clickable: this.clickable},
        {fit: this.fit},
        {selected: this.selected},
        {shrink: this.shrink},
        {error: this.status === 3},
        {plain: this.plain},
        {inline: this.inline},
        this.shadowClass,
        this.orientation
      ]
    }
  },
  watch: {
    src: 'init'
  },
  mounted() {
    if (this.isPrerender) {
      this.status = 2
      return
    }

    if (!('IntersectionObserver' in window)) {
      this.init()
    } else {
      this.observer = new IntersectionObserver(entries => {
        const entry = entries[entries.length - 1]
        if (!entry) {
          return
        }
        // Use `intersectionRatio` because of Edge 15's
        // lack of support for `isIntersecting`.
        // See: https://github.com/w3c/IntersectionObserver/issues/211
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          this.observer.unobserve(this.$el)
          this.init()
        }
      })
      this.observer.observe(this.$el)
    }
  },
  beforeDestroy() {
    this.observer && this.observer.disconnect()
  },
  methods: {
    onClick($event) {
      if (this.status === 3) {
        return
      }
      this.$emit('click', $event)
    },
    async init() {
      if (!this.link) {
        return
      }
      if (this.link === this.loaded) {
        return
      }
      try {
        clearTimeout(this.timeout)
        this.status = 0
        this.timeout = setTimeout(() => {
          this.status = 1
        }, 150)
        const {w, h} = await this.loadImage(this.link)
        this.orientation = w > h ? 'horizontal' : 'vertical'
        this.loaded = this.link
        if (w / h > 0.9 && w / h < 1.1) {
          this.orientation = 'near'
        }
        if (this.height === 'fit') {
          this.imageWidth = w
          this.imageHeight = h
        }
        this.status = 2
      } catch (err) {
        this.status = 3
      } finally {
        clearTimeout(this.timeout)
      }
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          const w = img.naturalWidth
          const h = img.naturalHeight
          resolve({w, h})
        }
        img.onerror = reject
        img.src = src
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.square {
  position: relative;
  width: 100%;
  background-color: #fff;
  line-height: 1.6;
  user-select: none;
  -webkit-touch-callout: none;
  overflow: hidden;

  .fa-spinner-third {
    color: #999;
  }

  &.skeleton {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.plain {
    background-color: transparent;
  }

  &.inline {
    display: inline-block;
  }

  &.error {
    background-color: #eee;
    color: #999;
  }

  &[data-mode="fit"] {
    &.vertical .square-image {
      width: 80%;
    }

    &.horizontal .square-image {
      height: 80%;
    }
  }

  &[data-mode="contain"] {
    &.vertical .square-image {
      width: auto;
    }

    &.horizontal .square-image {
      height: auto;
    }
  }

  &.disabled {
    opacity: .65;
    pointer-events: none;
  }

  &.selectable, &.clickable {
    cursor: pointer;
  }

  &.clickable {
    .square-inner {
      -webkit-tap-highlight-color: rgba(0, 0, 0, .1);

      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        pointer-events: none;
      }
    }

    &:active .square-inner:after {
      background-color: rgba(0, 0, 0, .05);
    }
  }

  .centered {
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    line-height: 1;
  }

  .mask {
    padding: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
    color: #fff;
    font-size: 12px;
    line-height: 1.4;
  }

  .square-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.disabled {
      pointer-events: none;
      user-select: none;
    }
  }

  .holder {
    pointer-events: none;
    visibility: hidden;
    display: block;
    padding-bottom: 100%;
  }

  .check {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 10;

    @keyframes pop {
      0% {
        transform: scale(.8);
      }
      70% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }

    .check-icon {
      position: absolute;
      display: block;
      right: 5px;
      bottom: 5px;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      background-color: $primary;
      color: #fff;
      z-index: 12;
      animation: pop .3s ease-out;
      box-shadow: $shadow-base;

      &:after {
        display: block;
        content: '';
        transform-origin: top right;
        transform: rotate(45deg);
        width: 6px;
        height: 12px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 5px;
        margin-bottom: 4px;
        z-index: 15;
      }
    }
  }

  .square-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
</style>
