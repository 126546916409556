import Vue from 'vue'

Vue.directive('headroom', {
  inserted(el, {value}) {
    const offsetTop = (() => {
      let result = 0
      let curEl = el
      while (curEl.offsetParent) {
        result += curEl.offsetTop
        curEl = curEl.offsetParent
      }
      return result
    })()
    el.onDocumentScroll = (e) => {
      if (!isVisible(el)) {
        return
      }
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      if (scrollTop >= offsetTop) {
        el.style.position = 'fixed'
        el.style.top = value
        el.style.marginTop = 0
        el.style.zIndex = 20
        el.classList.add('headroom')
      } else {
        el.classList.remove('headroom')
        el.removeAttribute('style')
      }
    }
    document.addEventListener('scroll', el.onDocumentScroll, false)
  },
  unbind(el) {
    document.removeEventListener('scroll', el.onDocumentScroll)
  }
})

function isVisible(e) {
  return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length)
}
