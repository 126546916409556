import Vue from 'vue'

Vue.directive('autofocus', {
  inserted: function (el) {
    el.autofocus = true
    if (el.style.display !== 'none' && !window.isMobile) {
      setTimeout(() => {
        el.focus()
      })
    }
  }
})
