<template>
  <header class="site-nav-container">
    <b-navbar class="site-nav">
      <b-navbar-brand to="/" @click.shift.meta.prevent="loginWithOpenid">
        <img alt="心书 微信书" class="logo" src="../assets/images/logo-wxbook-new.svg">
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/" exact>首页</b-nav-item>
        <template v-if="isAgent && !isDev">
          <b-nav-item href="/agent">
            <fa fw icon="flag"/>
            代理中心
          </b-nav-item>
        </template>
        <template v-else-if="hasLogin">
          <b-nav-item to="/books">书架</b-nav-item>
          <b-nav-item to="/orders">订单</b-nav-item>
          <b-nav-item to="/albums?tab=packages" excat>套装</b-nav-item>
          <b-nav-item to="/unboxing">晒单</b-nav-item>
        </template>
        <template v-else>
          <b-nav-item to="/products/wxbook">微信书</b-nav-item>
          <b-nav-item to="/products/wbbook">微博书</b-nav-item>
          <b-nav-item to="/products/blogbook">长文集</b-nav-item>
          <b-nav-item to="/unboxing">晒单</b-nav-item>
        </template>
        <b-nav-item to="/assistant" exact class="d-none d-md-block">
          <fa class="text-primary" fad fw icon="gift"/>
          送好友/导私密、评论
        </b-nav-item>
        <template v-if="hasLogin && user">
          <b-nav-item class="py-2" to="/my">
            <avatar :src="user.avatar" bordered size="2em" style="margin-right: .25em;"></avatar>
            <span class="nickname">{{user.nickname}}</span>
          </b-nav-item>
        </template>
        <template v-else>
          <li class="nav-item">
            <b-btn to="/signin" variant="primary">
              <fa icon="weixin" fab/>
              登录
            </b-btn>
          </li>
        </template>
      </b-navbar-nav>
    </b-navbar>

    <bottom-bar nav :items="mobileNavs"></bottom-bar>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isDropdownMenuShow: false,
      grades: ['', '9.8折', '9.5折', '9折', '8.5折', '8折']
    }
  },
  computed: {
    mobileNavs() {
      let navs = [
        {text: '首页', icon: 'home', to: '/'},
        {text: '晒单', icon: 'box-open', to: '/unboxing'},
        {text: '登录', icon: 'user', to: '/signin'}
      ]
      if (this.hasLogin) {
        navs = [
          {text: '首页', icon: 'home', to: '/'},
          {text: '晒单', icon: 'box-open', to: '/unboxing'},
          {text: '书架', icon: 'books', to: '/books'},
          {text: '订单', icon: 'credit-card', to: '/orders'},
          {text: '我的', icon: 'user', tag: this.grades[this.user.grade], to: '/my'}
        ]
      }
      if (this.isAgent) {
        navs = [
          {text: '首页', icon: 'home', to: '/'},
          {text: '代理中心', icon: 'flag', href: '/agent'},
          {text: '我的', icon: 'user', to: '/my'}
        ]
      }
      return navs
    }
  },
  methods: {
    loginWithOpenid() {
      let openid = prompt('请输入 OpenID 或 UnionID 模拟登录')
      if (!openid) {
        return
      }
      openid = openid.trim().split('"').join('')
      if (/^oi7rh/.test(openid)) {
        this.$store.commit('resetAuth')
        location.href = '/login?openid=' + openid + '&redirect=' + location.href
      }
      if (/^oVDOj/.test(openid)) {
        this.$store.commit('resetAuth')
        location.href = '/login?unionid=' + openid + '&redirect=' + location.href
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.site-nav {
  position: relative;
  z-index: 20;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid $hr-border-color;
  @include media-breakpoint-down(sm) {
    display: none;
  }

  .navbar-brand {
    padding-left: 0.75rem;

    .logo {
      height: 36px;
    }
  }

  .navbar-nav {
    .nav-item {
      text-align: center;
      position: relative;
      padding: 0.75em;

      .nav-link:not(.dropdown-toggle) {
        &:hover, &.active {
          color: $primary;

          &:after {
            display: block;
          }

        }
      }
    }

    .user-info, .link-btn {
      .nav-link:not(.dropdown-toggle):hover:after {
        display: none;
      }
    }

    li.link-btn .nav-link {
      padding: 0;

      .btn {
        padding: 8px 1rem;
        line-height: 1;
        font-size: 14px;
      }

      a:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }

  .user-info.nav-item {
    position: relative;
    max-width: 12rem;
    min-width: 8rem;
    cursor: pointer;

    .nav-link {
      padding: 0;
    }

    .nickname {
      max-width: 6em;
      @include text-overflow;
      display: inline-block;
      vertical-align: middle;
    }

    &:after {
      display: none !important;
    }

    &.show {
      background: #fff;
    }

    .dropdown-toggle:after {
      display: inline-block;
    }

    > a {
      padding: 0;

      &:hover {
        border-color: transparent;
      }

      .avatar {
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0.5rem 1rem;
    .navbar-nav li.link-btn .nav-link .btn {
      width: 100%;
    }
  }
}
</style>
