import { isEmpty } from 'lodash'

import { state } from '@/vuex/store'
import request from '@/plugins/request'
import { getKey } from '@/utils/utils'

export function fetchUser() {
  if (state.user && !isEmpty(state.user)) {
    return Promise.resolve(state.user)
  }
  return request.get('/api/user/info').then(data => {
    data.avatar = data.headimgurl
    delete data.headimgurl
    return data
  })
}

export function fetchUserProfile() {
  return request.get('/api/user/personal_agent/info')
}

export function fetchRevenue({type, page = 1}) {
  const params = {
    page_size: 12,
    page
  }
  if (type === 'cash') {
    return request.get('/api/user/personal_agent/redpacks/info', {params})
  }
  return request.get('/api/user/personal_agent/return_balance/info', {params})
}

export function fetchFans({page = 1}) {
  return request.get(`/api/user/personal_agent/fans/info?page_size=12&page=${page}`)
}

export function fetchUserInfo() {
  return request.get('/api/user/info').then(result => {
    result.avatar = result.headimgurl
    return result
  })
}

export function fetchGradeInfo() {
  return request.get('/api/user/configs/grade', {cache: true})
}

export function fetchAgentInfo() {
  return request.get('/api/user/info').then(getKey('agentInfo'))
}
