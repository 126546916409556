import Vue from 'vue'

Vue.use(require('bootstrap-vue/esm/bv-config').BVConfigPlugin, {
  BModal: {
    titleTag: 'h5',
    size: 'md',
    headerBgVariant: null,
    headerBorderVariant: null,
    headerTextVariant: null,
    headerCloseVariant: null,
    bodyBgVariant: null,
    bodyTextVariant: null,
    footerBgVariant: null,
    footerBorderVariant: null,
    footerTextVariant: null,
    cancelTitle: '取消',
    cancelVariant: 'secondary',
    okTitle: '好',
    okVariant: 'primary',
    headerCloseContent: '&times;',
    headerCloseLabel: '关闭'
  }
})

// Vue.use(require('bootstrap-vue/esm/components/alert').AlertPlugin)
Vue.use(require('bootstrap-vue/esm/components/badge').BadgePlugin)
// Vue.use(require('bootstrap-vue/esm/components/breadcrumb').default)
Vue.use(require('bootstrap-vue/esm/components/button').ButtonPlugin)
Vue.use(require('bootstrap-vue/esm/components/button-group').ButtonGroupPlugin)
// Vue.use(require('bootstrap-vue/esm/components/button-toolbar').ButtonToolbarPlugin)
Vue.use(require('bootstrap-vue/esm/components/input-group').InputGroupPlugin)
Vue.use(require('bootstrap-vue/esm/components/card').CardPlugin)
// Vue.use(require('bootstrap-vue/esm/components/carousel').default)
Vue.use(require('bootstrap-vue/esm/components/layout').LayoutPlugin)
// Vue.use(require('bootstrap-vue/esm/components/collapse').default)
// Vue.use(require('bootstrap-vue/esm/components/dropdown').default)
// Vue.use(require('bootstrap-vue/esm/components/embed').default)
// Vue.use(require('bootstrap-vue/esm/components/form').default)
Vue.use(require('bootstrap-vue/esm/components/form-group').FormGroupPlugin)
Vue.use(require('bootstrap-vue/esm/components/form-checkbox').FormCheckboxPlugin)
// Vue.use(require('bootstrap-vue/esm/components/form-radio').FormRadioPlugin)
Vue.use(require('bootstrap-vue/esm/components/form-input').FormInputPlugin)
// Vue.use(require('bootstrap-vue/esm/components/form-textarea').default)
// Vue.use(require('bootstrap-vue/esm/components/form-file').default)
Vue.use(require('bootstrap-vue/esm/components/form-select').FormSelectPlugin)
// Vue.use(require('bootstrap-vue/esm/components/image').default)
// Vue.use(require('bootstrap-vue/esm/components/jumbotron').default)
Vue.use(require('bootstrap-vue/esm/components/link').LinkPlugin)
// Vue.use(require('bootstrap-vue/esm/components/list-group').default)
// Vue.use(require('bootstrap-vue/esm/components/media').default)
Vue.use(require('bootstrap-vue/esm/components/modal').ModalPlugin)
Vue.use(require('bootstrap-vue/esm/components/nav').NavPlugin)
Vue.use(require('bootstrap-vue/esm/components/navbar').NavbarPlugin)
Vue.use(require('bootstrap-vue/esm/components/pagination').PaginationPlugin)
// Vue.use(require('bootstrap-vue/esm/components/pagination-nav').default)
// Vue.use(require('bootstrap-vue/esm/components/popover').PopoverPlugin)
// Vue.use(require('bootstrap-vue/esm/components/progresms').default)
// Vue.use(require('bootstrap-vue/esm/components/spinner').default)
// Vue.use(require('bootstrap-vue/esm/components/table').default)
Vue.use(require('bootstrap-vue/esm/components/tabs').TabsPlugin)
Vue.use(require('bootstrap-vue/esm/components/tooltip').TooltipPlugin)
