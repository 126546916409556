import request from '@/plugins/request'
import { ss } from '@/plugins/storage'

const r = request.create({
  baseURL: '/buckets/',
  timeout: 10000,
  cache: true
})

r.interceptors.response.use(res => res.data)

export function fetchHomeConfig() {
  return r.get('weixinshu:home')
}

export function fetchPromotion() {
  return r.get('weixinshu:promotion')
}

export function fetchShelfConfig() {
  return r.get('weixinshu:shelf')
}

export function fetchOrderTips() {
  return r.get('weixinshu:orderTipsNew')
}

export function fetchCashbackConfig() {
  return r.get('weixinshu:cashback')
}

export function fetchCoverTemplates() {
  return r.get('weixinshu:covers')
}

export function fetchTemplates() {
  return r.get('weixinshu:templates')
}

export function fetchInserts() {
  return r.get('weixinshu:inserts')
}

export function fetchFooters() {
  return r.get('weixinshu:footers')
}

export function fetchGallery() {
  return r.get('weixinshu:gallery')
}

export function fetchAlbumStyles() {
  return Promise.all([
    r.get('xiangce:styles'),
    r.get('weimeng:styles')
  ]).then(([a, b]) => [...a, ...b])
}

export function fetchWeimengProducts() {
  return r.get('weimeng:products')
}

export function fetchWeimengTemplates() {
  return r.get('weimeng:templates')
}

export function fetchWeimengStyles() {
  return r.get('weimeng:styles')
}

export function fetchAlbumProducts() {
  const cache = ss.getItem('xiangce:products')
  if (cache) {
    return Promise.resolve(JSON.parse(cache))
  }
  return r.get('xiangce:products').then(data => {
    ss.setItem('xiangce:products', JSON.stringify(data))
    return data
  })
}

export function fetchAlbumTemplates() {
  return r.get('xiangce:templates')
}

export function fetchPackages() {
  return r.get('weixinshu:packages')
}

export function fetchScenarios() {
  return r.get('xiangce:scenarios')
}

export function fetchAlbumAds() {
  return r.get('xiangce:home')
}

export function fetchCategories() {
  return r.get('xiangce:categories')
}

export function fetchSpecials() {
  return r.get('xiangce:specials')
}
