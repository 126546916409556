<template>
  <div class="tips" :style="style" v-show="active">
    <b>
      <slot></slot>
    </b>
    <transition name="fade">
      <b-link @click="close" v-if="showClose" class="small">我知道啦</b-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'tips',
  props: {
    target: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      active: false,
      showClose: false,
      lsKey: 'guide[' + [this.$route.name, this.target].join(':') + ']',
      top: 0,
      left: 0,
      height: null
    }
  },
  mounted() {
    this.active = !this.$ls.get(this.lsKey)

    if (!this.active) {
      return
    }

    this.$nextTick(() => {
      this.calcPosition()
    })

    setTimeout(() => {
      this.showClose = true
    }, 1000)
  },
  events: {
    resize: 'calcPosition'
  },
  methods: {
    close() {
      this.active = false
      this.$ls.set(this.lsKey, 1)
    },
    calcPosition() {
      const targetEl = document.querySelector(this.target)
      if (targetEl && this.$el) {
        const tRect = targetEl.getBoundingClientRect()
        const sRect = this.$el.getBoundingClientRect()
        this.left = tRect.left - sRect.left
      }
    }
  },
  computed: {
    style() {
      return {
        paddingLeft: this.left + 'px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .tips {
  }
</style>
