import Vue from 'vue'
import { isFunction } from 'lodash'

Vue.directive('prevent', {
  bind(el, binding) {
    el.onClick = e => {
      if (isFunction(binding.value)) {
        e.preventDefault()
        binding.value()
      }
    }
    el.addEventListener('click', el.onClick, false)
  },
  unbind(el) {
    el && el.removeEventListener('click', el.onClick)
  }
})
