'use strict'

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    let el = this

    do {
      if (el.matches(s)) {
        return el
      }
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

if (!document.elementsFromPoint) {
  document.elementsFromPoint = elementsFromPoint
}

function elementsFromPoint(x, y) {
  const parents = []
  let parent = null
  do {
    const element = document.elementFromPoint(x, y)
    if (parent !== element) {
      parent = element
      parents.push(parent)
      parent.style.pointerEvents = 'none'
    } else {
      parent = null
    }
  } while (parent)

  parents.forEach(function (parent) {
    parent.style.pointerEvents = 'all'
  })

  return parents
}
