import Vue from 'vue'
import { blankPic } from '@/config'

Vue.directive('src', {
  bind: init,
  update: init
})

function init(el, {value}) {
  if (!el.src) {
    el.src = blankPic
  }
  if (value && value !== el.src) {
    el.style.opacity = 0
    el.style.transition = 'opacity .3s'
    el.src = value
    if (el.complete) {
      el.style.opacity = 1
      return
    }
    el.onload = () => {
      el.style.opacity = 1
    }
    el.onerror = () => {
      el.style.opacity = 1
      el.src = blankPic
    }
  }
}
