<template>
  <img :src="src" alt="" :style="style">
</template>

<script>
export default {
  name: 'emotion',
  props: {
    size: String,
    value: {
      type: String,
      validator(val) {
        return [
          'angry',
          'cry',
          'cute',
          'fire',
          'funny',
          'furious',
          'happy',
          'love',
          'rich',
          'study',
          'wail',
          'wink'
        ].indexOf(val) > -1
      }
    }
  },
  computed: {
    src() {
      return 'https://static.weixinshu.com/assets/images/deer/' + this.value + '.png'
    },
    style() {
      return {
        maxWidth: this.size
      }
    }
  }
}
</script>

<style scoped>

</style>
