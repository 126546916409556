import { get, isEmpty, isPlainObject, round, trim } from 'lodash'
import dayjs from 'dayjs'
import request from './plugins/request'
import { state } from './vuex/store'

export function fetchUserCount() {
  return request.get('/api/latest_wxbook_user')
}

export function fetchCoupons() {
  return request.get('/api/user/coupons').then(data => data.map(extendCoupon))
}

export function fetchRedeemCodes() {
  return request.get('/api/user/redeem_codes').then(items => {
    return items.map(i => {
      i.orderId = i.orderIds[0]
      i.status = 0
      if (i.expireAt) {
        i.hasExpired = new Date(i.expireAt) < new Date()
        i.hidden = new Date(i.expireAt) < new Date() - 30 * 24 * 3600 * 1000
      }
      if (i.aid) {
        i.status = 1
      }
      if (i.orderId) {
        i.status = 2
      }
      return i
    })
  })
}

export function fetchCouponsCount() {
  return request.get('/api/user/coupons').then(data => data.length)
}

export function fetchCouponActivities({couponNo}) {
  if (couponNo) {
    return fetchCouponSets({couponNo})
  }
  return request.get('/api/user/coupon_activities').then(data => data.map(extendCoupon))
}

export function fetchCouponSets({couponNo}) {
  const couponNos = couponNo.split(',').map(i => i?.trim?.() || '').filter(Boolean)
  return Promise.all(couponNos.map(couponNo => fetchCoupon({couponNo})))
    .then(results => results.filter(Boolean))
}

export function fetchCoupon({couponNo}) {
  return request.get(`/api/user/coupon_activity/${couponNo}`)
    .then(data => data ? data[0] : null)
    .then(extendCoupon)
}

export function fetchCashback({orderNo}) {
  return request.get('/api/helpdesk/request/get_by_order/' + orderNo)
    .then(result => isEmpty(result) ? null : result)
    .catch(() => null)
}

export function fetchRepair({orderNo}) {
  return request.get('/api/helpdesk/request/get_by_order/' + orderNo + '?category=4')
    .then(result => isEmpty(result) ? null : result)
    .catch(() => null)
    .then(repair => {
      if (!repair || !repair.id) {
        return null
      }
      const content = repair.content
      const prefer = (content.match(/^#.+?#/) || [''])[0]

      repair.prefer = trim(prefer, '#')
      repair.content = content.replace(prefer, '')

      return repair
    })
}

export function fetchUnboxingItems({page = 1, tid}) {
  return request.get('/api/helpdesk/request/get_all_cashback', {
    params: {
      page,
      tid
    }
  }).then(data => {
    return data.filter(item => item.rank >= 0).map(item => {
      item.picList = item.picList.filter(Boolean)
      item.binding = item.book ? item.book.binding : ''
      item.type = item.book ? item.book.type : ''
      return item
    })
  })
}

export function fetchUnboxingItem({id}) {
  return request.get(`/api/helpdesk/request/${id}/info`)
}

export function fetchRankCashback({tid}) {
  return request.get('/api/helpdesk/request/get_all_cashback_rank', {
    params: {
      size: 6,
      tid
    }
  }).then(data => {
    return data.filter(item => item.rank >= 0).map(item => {
      item.picList = item.picList.filter(Boolean)
      item.binding = item.book ? item.book.binding : ''
      item.type = item.book ? item.book.type : ''
      return item
    })
  })
}

export function fetchLibraryBooks({filter}) {
  let url = '/api/library/books?page=1&page_size=24&type=personal'
  if (filter) {
    url += '&filter=' + filter
  }
  return request.get(url).then(getKey('booksList'))
}

// 余额
export function fetchBalance() {
  return request.get('/api/user/balance')
}

// 余额明细
export function fetchBalanceDetail({page = 1}) {
  return request.get(`/api/user/balance/detail?page=${page}`)
}

// 获取充值卡列表
export function fetchGiftCards() {
  return request.get('/api/gift_card/list').then(data => data.map(item => {
    const [desc, discount] = item.description.split('#')
    item.description = desc
    item.discount = discount
    return item
  }))
}

// 获取小编链接
export function fetchXiaobian() {
  return request.get(`/api/wxbook/xiaobian?openid=${state.user.openid}`).then(result => {
    if (result.qrcode) {
      result.qrcode = 'https://static.weixinshu.com' + result.qrcode.replace('.png', '.jpeg')
    }
    return result
  })
}

function getKey(key) {
  return (data) => {
    return get(data, key)
  }
}

function extendCoupon(coupon) {
  if (isPlainObject(coupon)) {
    if (coupon.maxTime) {
      coupon.remainTimes = coupon.maxTime - coupon.time
      coupon.exhausted = coupon.remainTimes <= 0
    }

    if (coupon.price >= 1) {
      // price >= 是抵价券，否则是折扣券
      coupon.value = round(coupon.price) + '元'
      if (coupon.maxDiscount > 0) {
        coupon.minUsePrice = round(coupon.price / (1 - coupon.maxDiscount), 2)
      }
    } else {
      coupon.value = round(coupon.price * 10, 2) + '折'
    }

    coupon.isToday = dayjs(coupon.startAt).diff(new Date(), 'hour') < 24
    coupon.hasStarted = new Date(coupon.startAt) <= Date.now()
    coupon.hasExpired = new Date(coupon.endAt) <= Date.now()

    coupon.publishTypes = coupon.publishTypes || []
    coupon.products = coupon.products || []
    coupon.binding = coupon.binding || []

    coupon.hasRestriction = [
      coupon.publishTypes,
      coupon.products,
      coupon.binding
    ].some(l => l.length)

    coupon.name = coupon.name || coupon.activity?.name || coupon.extra?.name || ''
  }
  return coupon
}

export * from './models/user'
export * from './models/book'
export * from './models/order'
export * from './models/album'
export * from './models/configuration'
export * from './models/blogbook'
