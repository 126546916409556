/**
 * Created by chenzhuokai on 2017/2/24.
 */
import Vue from 'vue'
import autosize from 'autosize'

Vue.directive('autosize', {
  inserted(el, {value}) {
    if (el.tagName !== 'TEXTAREA') {
      return
    }
    if (value) {
      el.style.maxHeight = value
    }
    setTimeout(() => {
      autosize(el)
      el.onkeyup = () => {
        autosize(el)
      }
    })
  },
  unbind(el) {
    el.onkeyup = null
  }
})
