<template>
  <div :class="[border ? 'border-' + border : '', {dark}]" class="empty-sign text-muted">
    <img :alt="emotion" :src="emotionUrl" class="emotion" v-if="emotion">
    <fa class="main-icon" fad :icon="icon" v-else-if="icon" :size="iconSize"/>
    <slot>{{text}}</slot>
  </div>
</template>

<script>
export default {
  name: 'emptySign',
  props: {
    icon: {
      type: String,
      default() {
        if (this.emotion) {
          return ''
        }
        return 'list'
      }
    },
    iconSize: {
      default: '5x'
    },
    dark: Boolean,
    text: String,
    emotion: {
      type: String,
      validator(val) {
        return [
          'angry',
          'cry',
          'cute',
          'fire',
          'funny',
          'furious',
          'happy',
          'love',
          'rich',
          'study',
          'wail',
          'wink'
        ].indexOf(val) > -1
      }
    },
    border: String
  },
  computed: {
    emotionUrl() {
      return 'https://static.weixinshu.com/assets/images/deer/' + this.emotion + '.png!320'
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-sign {
  text-align: center;
  padding: 2em 1em;
  padding-bottom: 1.5em;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.dark {
    background-color: $gray-100;
  }

  .emotion {
    display: block;
    margin: 0 auto 1em;
    max-width: 15rem;
  }

  .main-icon {
    display: block;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
