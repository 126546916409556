import Cookies from 'js-cookie'
import { ss } from '@/plugins/storage'

const domain = /^10\.|^192\.|localhost/.test(location.hostname) ? '' : '.' + location.hostname

export default {
  setWinSize(state, sizes) {
    state.winSize = sizes
  },
  setSource(state, source) {
    state.source = source
    ss.setItem('source', source)
  },
  setToken(state, {authToken, expireIn = 604800}) {
    state.token = authToken
    console.log('Setting Token', authToken, expireIn)
    if (authToken) {
      Cookies.remove('wxbook.token')
      Cookies.remove('wxbook.token', {domain})
      const options = {domain}
      if (expireIn) {
        options.expires = expireIn / 3600 / 24
      }
      Cookies.set('wxbook.token', authToken, options)
    }
  },
  setUser(state, user) {
    if (user?.token) {
      this.commit('setToken', user.token)
      user.token = user.token.authToken
    }
    console.log('Setting User', user)
    state.user = user
    if (user) {
      Cookies.remove('wxbook.user')
      Cookies.remove('wxbook.user', {domain})
      Cookies.set('wxbook.user', JSON.stringify(user), {expires: 7, domain})
    }
  },
  setError(state, err) {
    state.error = err
  },
  setPrevRoute(state, route) {
    if (!route || !route.name) {
      state.prevRoute = null
    } else {
      state.prevRoute = route
      state.routeHistory.unshift(route)
      if (state.routeHistory.length > 20) {
        state.routeHistory = state.routeHistory.slice(0, 20)
      }
    }
  },
  resetAuth(state) {
    state.token = ''
    state.user = {}
    sessionStorage.clear()
    Cookies.remove('wxbook.token')
    Cookies.remove('wxbook.token', {domain})
    Cookies.remove('wxbook.user')
    Cookies.remove('wxbook.user', {domain})
  }
}
