import Vue from 'vue'

Vue.directive('autoScale', {
  inserted(el) {
    resize(el)
    setTimeout(() => {
      resize(el)
    })
    el.onresize = () => {
      resize(el)
    }
    window.addEventListener('resize', el.onresize)
  },
  unbind(el) {
    window.removeEventListener('resize', el.onresize)
  }
})

function resize(el) {
  if (!el) {
    return
  }

  el.style.height = 'auto'
  const pageHeightTotal = el.clientHeight
  const width = el.clientWidth
  const contentWidth = el.scrollWidth
  if (width >= contentWidth) {
    el.removeAttribute('style')
    return
  }
  const scale = width / contentWidth
  el.style.transform = `scale(${scale})`
  el.style.webkitTransform = `scale(${scale})`
  el.style.transformOrigin = '0 0'
  el.style.webkitTransformOrigin = '0 0'
  el.style.height = pageHeightTotal * scale + 'px'
}
