/**
 * Created by kk on 17/7/19.
 */
import Vue from 'vue'
import { loadImage, serializeToFormString } from '@/utils/utils'

Vue.directive('image-text', {
  inserted(el) {
    let {url, font, padding} = el.dataset
    const content = el.textContent.trim()
    const {textAlign, color, lineHeight, fontSize} = getComputedStyle(el)

    const query = {
      content: content,
      fillStyle: rgb2hex(color),
      textAlign: textAlign,
      fontFamily: font || 'FZQingKeBenYueSongS-R-GB',
      lineHeight: parseFloat(lineHeight) / parseFloat(fontSize),
      padding: Number(padding) || 0,
      format: 'svg',
      fontSize: 20
    }

    url = 'https://canvas.xinshu.me/text' + serializeToFormString(query, true, false)

    if (color) {
      el.style.setProperty('color', 'transparent', 'important')
    }

    loadImage(url).then(() => {
      el.classList.add('image-text')
      el.style.backgroundImage = `url(${url})`
      el.style.backgroundPosition = [textAlign, 'center'].join(' ')
      el.style.backgroundSize = 'contain'
      el.style.backgroundRepeat = 'no-repeat'
      el.style.whiteSpace = 'nowrap'
    })
  }
})

function rgb2hex(rgb) {
  rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

  function hex(x) {
    return ('0' + parseInt(x).toString(16)).slice(-2)
  }

  return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3])
}
