<template>
  <div class="text-center page-404 tag-primary">
    <h2 class="title">
      出错啦
    </h2>
    <h4>没有找到相关页面哦</h4>
    <p>
      <img src="https://static.weixinshu.com/assets/images/deer/cry.png" style="width: 240px;">
    </p>
    <template v-if="error">
      <div class="alert alert-primary" v-if="error">
        链接：{{$route.fullPath}}
        <br>
        如果您持续遇到此问题，请截图并发送给客服
      </div>
      <div>
        <qiyu class="btn-lg">联系客服</qiyu>
        <b-btn size="lg" href="javascript:history.back()">返回</b-btn>
      </div>
    </template>
    <div v-else>
      <template v-if="isPartnerUser">
        <p>
          <b-btn block @click="reload" @click.shift="logout">刷新试试</b-btn>
          <b-btn block variant="link" href="javascript:history.back()">返回</b-btn>
          <b-btn block @click="logout" style="opacity: 0;">重新登录</b-btn>
        </p>
      </template>
      <template v-else>
        <b-btn to="/" variant="primary" size="lg" exact>返回首页</b-btn>
        <b-btn :to="{name: 'unboxing'}" variant="success" size="lg" exact>书友晒单</b-btn>
      </template>
    </div>
  </div>
</template>

<script>
import Qiyu from '@/components/Qiyu'

export default {
  name: 'notFound',
  components: {Qiyu},
  data() {
    return {
      error: false
    }
  },
  mounted() {
    if (this.$ls.get('404') === this.$route.fullPath) {
      this.error = true
    }
    this.$req.get('/report/404')
    this.$ls.set('404', this.$route.fullPath)
  },
  methods: {
    logout() {
      this.$store.commit('resetAuth')
      location.href = '/signin'
    },
    reload() {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-404 {
  padding-top: 3rem;
  position: relative;
  max-width: 640px;
  word-break: break-all;

  &[data-tag]:before {
    font-size: 1.25em;
  }

  .icon-404 {
    color: $red;
    font-size: 16rem;
    display: block;
    vertical-align: top;
  }
}
</style>
