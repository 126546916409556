/**
 * Created by bruce on 2017/3/22.
 */
import { get } from 'lodash'
import { mapGetters } from 'vuex'
import { getBookId, getCoverUrl } from '@/models/book'
import { serializeToFormString, loadImage, proxyUrl, base64Url } from '@/utils/utils'
import { bookTypes } from '@/config'

export default {
  computed: {
    ...mapGetters(['token', 'user', 'isSmallScreen']),
    debug() {
      return !!this.$ss.getItem('debug')
    },
    hasLogin() {
      if (this.isAgentUser) {
        return true
      }
      return Boolean(this.token && this.user)
    },
    openid() {
      return get(this.user, 'openid')
    },
    hasSubscribed() {
      return !!get(this.user, 'subscribe')
    },
    isAgent() {
      return !!get(this.user, 'isAgent')
    },
    isNewbie() {
      return get(this.user, 'paidOrderCount') === 0
    },
    userSource() {
      return this.$store.state.source
    },
    isAgentUser() {
      return this.userSource === 'agent' || /^agent/.test(this.token)
    },
    isWorkshopUser() {
      return this.userSource === 'workshop'
    },
    isWeimengUser() {
      return this.userSource === 'weimeng'
    },
    isPartnerUser() {
      return this.isAgentUser || this.isWorkshopUser || this.isWeimengUser
    },
    hasUser() {
      return !this.isAgentUser
    },
    isMiniProgram() {
      return window.__wxjs_environment === 'miniprogram'
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('setPrevRoute', from)
    })
  },
  methods: {
    scrollIntoView(selector, fallback, forceTop) {
      if (!this.$el?.querySelector) {
        return
      }
      let el = selector
      if (typeof selector === 'string') {
        el = this.$el.querySelector(selector)
      }
      if (fallback && !el) {
        el = fallback
        if (typeof fallback === 'string') {
          el = this.$el.querySelector(fallback)
        }
      }
      if (el) {
        try {
          if (forceTop === true) {
            el.scrollIntoView()
          } else {
            el.scrollIntoViewIfNeeded()
          }
        } catch (err) {
          el.scrollIntoView()
        }
      }
      return el
    },
    serialize: serializeToFormString,
    loadImage: loadImage,
    getBookId,
    getCoverUrl,
    getTypeset(val) {
      val = val.replace('a5', 'A5')
      return bookTypes[val] || val
    },
    $base64encode: base64Url.encode,
    $img: proxyUrl
  }
}
