<template>
  <div class="browser-notification">
    <div>
      <emotion value="cry"/>
    </div>
    <h2>对不起，当前系统版本太低</h2>
    <p>请升级后再使用</p>
    <b-btn variant="primary" block href="https://support.apple.com/zh-cn/HT204204" target="_blank">如何升级系统？</b-btn>
  </div>
</template>

<script>
export default {
  name: 'browserNotification'
}
</script>

<style lang="scss" scoped>
  .browser-notification {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
  }
</style>
