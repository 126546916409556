<template>
  <div class="side-btns" :class="{'active': shown || !isSmallScreen}">
    <qiyu v-if="user && user.openid"/>
  </div>
</template>
<script>
import Qiyu from './Qiyu'

export default {
  name: 'sideBtns',
  components: {Qiyu},
  computed: {
    shown() {
      return [
        'home',
        'books',
        'book',
        'book.inner',
        'buy',
        'check',
        'orders',
        'create'
      ].includes(this.$route.name)
    }
  }
}
</script>
<style>
#YSF-PANEL-INFO.ysf-chat-layer {
  right: 100px;
}
</style>
<style lang="scss" scoped>
.side-btns {
  position: fixed;
  right: 0;
  bottom: 108px;
  margin-bottom: -3rem;
  z-index: 5;
  flex-direction: column;
  transform: translateX(100%);
  transition: 1s;

  &.active {
    transform: translateX(0);
  }

  ::v-deep .btn {
    display: block;
    width: 2.5em;
    white-space: inherit;
    text-align: center;
    padding: .5em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transform: translateX(10%);
    transition: .3s;
    line-height: 1.2;
    opacity: .9;

    &:hover {
      opacity: 1;
      transform: translateX(0);
    }

    @include media-breakpoint-down(sm) {
      width: auto;
      transform: translateX(0);
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      padding-left: 1em;
      font-size: 12px;
    }

    i {
      margin-bottom: .25em;
    }
  }
}
</style>
