export const imgHost = 'https://img.xinshu.me'
export const blankPic = 'data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEXs7Oxc9QatAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg=='
export const anonymousUserPic = require('./assets/images/anonymous-user.jpg')

export const bindings = {
  0: '经济装',
  1: '文艺装',
  2: '精装',
  3: '体验装',
  pdf: '电子版'
}
export const statuses = ['未付款', '已付款', '正在印刷', '已发货', '正在印刷', '已退款']
export const bookTypes = {
  // eslint-disable-next-line quote-props
  'A4': 'A4杂志版(旧)',
  'A5-1': 'A5瀑布流(旧)',
  'A5-2': 'A5双栏(旧)',
  'A5-WF': 'A5故事集',
  'A5-3': 'A5时光轴',
  'A5-4': 'A5拾光',
  'A4-1': 'A4瀑布流',
  'A4-1-child': 'A4童趣版',
  'A4-1-home': 'A4家春秋版',
  'A5-5': 'A5瀑布流',
  'A5-6': 'A5双栏',
  'A4-2': 'A4杂志版',
  'A5-5-child': 'A5童趣版',
  'A5-blog': 'A5长文集',
  'A4-blog': 'A4长文集',
  'A4-2-blog': 'A4图片双栏',
  'A4-3': 'A4双栏'
}
export const shipCompanies = {
  shunfeng: '顺丰快递',
  yuantong: '圆通快递',
  zhongtong: '中通快递',
  youzheng: '邮政快递'
}
export const albumProducts = {
  album: ['精装纪念册', 'A5精装纪念册'],
  balbum: ['文绘集', '文绘集'],
  calbum: ['软装纪念册', 'A4软装纪念册'],
  calendar: ['2020经典台历', '2020台历'],
  xsalbum: ['皮面册', '四季常伴·皮面册'],
  'calendar-h': ['2019经典台历横款', '2019台历'],
  'calendar-v': ['2019经典台历竖款', '2019台历'],
  'square-album': ['写真本', '蝴蝶写真本'],
  'calendar-metal': ['铁艺台历', '铁艺台历'],
  'fabric-album': ['横款照片书', '横款照片书'],
  'xsalbum-package': ['皮面册礼品卡', '皮面册礼品卡'],
  xcalbum: ['杂志册', 'A4轻奢杂志册'],
  note: ['笔记本', '亲子笔记本'],
  xalbum: ['精装纪念册', 'A4精装纪念册'],
  'chat-album': ['聊天记录书', '聊天记录书'],
  b5album: ['锁线精装照片书', '锁线精装照片书'],
  'calendar-2021': ['经典台历', '经典台历2021'],
  'frame-moment': ['朋友圈相框', '朋友圈相框'],
  postcard: ['LOMO卡', 'LOMO卡'],
  picturebook: ['绘本', '创意绘本'],
  'usho-album': ['友笑纪念册', '友笑纪念册'],
  'calendar-2022': ['经典台历', '经典台历2022']
}
export const bookProducts = {
  wxbook: ['微信书', '微信书'],
  wbbook: ['微博书', '微博书'],
  shuoshuo_book: ['说说书', '说说书'],
  diarybook: ['日记书', '日记书'],
  storybook: ['故事集', '心书故事集'],
  bbsbook: ['宝宝树小时光', '宝宝树小时光'],
  qbbbook: ['亲宝宝书', '亲宝宝书'],
  'star-wbbook': ['明星微博书', '明星微博书'],
  blogbook: ['长文集', '长文集']
}
export const studioProducts = {
  salbum: ['皮面册', '精美珍藏级皮面相册'],
  lsalbum: ['轻奢皮面册', '轻奢皮面册'],
  xalbum: ['精装纪念册', 'A4精装纪念册'],
  'square-album': ['写真本', '蝴蝶写真本']
}
export const wmProducts = {
  'wm-calbum': ['杂志册', '12寸杂志册'],
  'wm-salbum': ['皮册', '方9寸皮册'],
  'wm-album': ['精品韩册', '精品韩册'],
  'wm-square-album': ['至尊韩册', '至尊韩册'],
  'wm-calendar-2021': ['2021台历', '2021经典台历'],
  'wm-b5album': ['锁线册', '10寸锁线精装照片书']
}
export const offlineProducts = ['calendar-metal', 'calendar-h', 'calendar-v', 'storybook']
