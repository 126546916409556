/**
 * Created by chenzhuokai on 2016/10/13.
 */
import { isFunction, isUndefined } from 'lodash'

export const wxConfigReady = () => {
  if (window.isWechat) {
    return until(() => window.WeixinJSBridge && window.wx && window.wx.configReady)
  }
  return Promise.reject(new Error('Not in wechat'))
}
export const wxReady = () => {
  if (window.isWechat) {
    return until(() => window.WeixinJSBridge && window.wx)
  }
  return Promise.reject(new Error('Not in wechat'))
}

export function wait(time, error) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error) {
        return reject(error)
      }
      resolve()
    }, time)
  })
}

/**
 * until
 * @param {Function} condition
 * @param {Number} duration
 * @param {Number} times
 * @return {Promise}
 */
export function until(condition, duration = 100, times = 100) {
  if (isUndefined(condition)) {
    return Promise.reject(new Error('Callback is not function'))
  }

  return new Promise((resolve, reject) => {
    if (isFunction(condition)) {
      if (condition()) {
        resolve()
        return
      }
      const interval = setInterval(() => {
        if (condition()) {
          clearInterval(interval)
          resolve()
          return
        }
        if (times < 0) {
          clearInterval(interval)
          return
        }
        times--
      }, duration)
    } else {
      reject(new SyntaxError('Condition must be a function'))
    }
  })
}
