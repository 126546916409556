/**
 * Created by chenzhuokai on 2017/1/9.
 */
import Vue from 'vue'

Vue.directive('errorPlaceholder', {
  inserted(el) {
    el.onerror = (e) => {
      const src = e.target.src
      e.target.style.objectFit = 'cover'
      e.target.src = require('../assets/images/error.jpg')
      e.target.setAttribute('data-src', src)
      e.target.setAttribute('data-error', true)
    }
  },
  unbind(el) {
    el.onerror = null
  }
})
