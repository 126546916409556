import { setTitle } from '@/utils/utils'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$setTitle(vm.$options.title)
    })
  },
  watch: {
    $route() {
      if (this.$options.title) {
        this.$setTitle(this.$options.title)
      }
    }
  },
  methods: {
    $setTitle(title) {
      title = title || '朋友圈回忆，一键成书'
      if (typeof title === 'function') {
        title = title.call(this)
      }
      setTitle(title)
    }
  }
}
