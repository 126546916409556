import Vue from 'vue'
import $root from '../main'

const dialog = {
  confirm(options) {
    if (typeof options === 'string') {
      options = {content: options}
    }
    const {content, ...params} = options
    params.title = params.title || '确认'
    if (params.keep) {
      delete params.keep
      params.noCloseOnBackdrop = true
      params.noCloseOnEsc = true
      params.hideHeaderClose = true
    }
    const vm = new Vue({
      template: `
        <b-modal ref="modal" v-bind="props"
                 v-model="modalShow"
                 auto-focus-button="ok"
                 @hidden="$emit('hidden')"
                 @ok="$emit('ok')"
                 @cancel="$emit('cancel')">
        ${content || ''}
        </b-modal>`,
      data() {
        return {
          modalShow: true,
          props: {
            okTitle: '好',
            cancelTitle: '取消',
            size: 'sm',
            noEnforceFocus: true,
            hideHeader: !params.title,
            ...params
          }
        }
      },
      parent: $root,
      watch: {
        $route() {
          this.modalShow = false
        }
      }
    })

    document.body.appendChild(vm.$mount().$el)

    return new Promise(resolve => {
      vm.$once('ok', () => resolve(true))
      vm.$once('cancel', () => resolve(false))
      vm.$once('hidden', () => {
        vm.$el.remove()
        vm.$destroy()
        resolve(null)
      })
    })
  },
  prompt({content, placeholder, defaultValue, ...params}) {
    const vm = new Vue({
      template: `
        <b-modal ref="modal" v-bind="props" v-model="modalShow"
                 @hidden="$emit('hidden')" @ok="onConfirm" @cancel="$emit('cancel')">
        ${content ? '<p>' + content + '</p>' : ''}
        <b-input v-model="text" @keyup.enter="$refs.modal.hide('ok')" autofocus
                 maxlength="${params.maxlength || 50}" placeholder="${placeholder || ''}"/>
        </b-modal>`,
      data() {
        return {
          modalShow: true,
          text: defaultValue || '',
          props: {
            okTitle: '确认',
            cancelTitle: '取消',
            size: 'sm',
            noEnforceFocus: true,
            hideHeader: !params.title,
            ...params
          }
        }
      },
      parent: $root,
      watch: {
        $route() {
          this.modalShow = false
        }
      },
      methods: {
        onConfirm(evt) {
          if (!params.optional && !this.text) {
            evt.preventDefault()
            if (params.alert) {
              params.alert.call(this)
            }
            return
          }
          this.$emit('ok')
        }
      }
    })

    document.body.appendChild(vm.$mount().$el)

    return new Promise(resolve => {
      vm.$once('ok', () => resolve(vm.text))
      vm.$once('cancel', () => resolve(null))
      vm.$once('hidden', () => {
        vm.$el.remove()
        vm.$destroy()
        resolve(null)
      })
    })
  }
}

export default dialog

export function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  Vue.dialog = dialog
  Object.defineProperties(Vue.prototype, {
    $dialog: {
      get() {
        return Vue.dialog
      }
    }
  })
}
