<template>
  <div class="grid-item" :style="style" @click="$emit('click')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'gridItem',
  computed: {
    style() {
      if (window.innerWidth > 640) {
        return {
          padding: this.padding
        }
      }
      return {
        padding: this.padding
      }
    }
  }
}
</script>

<style>
  .grid-item {
    width: 100%;
    flex-basis: 100%;
  }
</style>
