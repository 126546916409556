export default {
  methods: {
    $throwError(err) {
      if (typeof err === 'string') {
        err = new Error(err)
      }
      this.$store.commit('setError', err)
    },
    $clearError() {
      this.$store.commit('setError', null)
    }
  }
}
