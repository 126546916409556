<template>
  <div class="skeleton" :class="[{block}]" :data-type="type" :style="style"></div>
</template>

<script>
export default {
  name: 'skeleton',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    width: String,
    height: String,
    block: Boolean
  },
  computed: {
    style() {
      const styles = {}
      if (this.width) {
        styles.width = this.width
      }
      if (this.type === 'square' || this.type === 'page') {
        styles['padding-bottom'] = this.height || '100%'
      }
      return styles
    }
  }
}
</script>

<style lang="scss" scoped>
.skeleton {
  position: relative;
  background-color: $hr-border-color;
  overflow: hidden;
  display: inline-block;
  width: 100%;

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  &.block {
    display: block;

    &[data-type="text"] {
      margin-bottom: .25em;
    }
  }

  &:after {
    content: '';
    display: block;
    pointer-events: none;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 2s infinite;
    width: 100%;
    height: 1em;
  }

  &[data-type="cover"] {
    &:after {
      padding-bottom: 142%;
    }
  }

  &[data-type="icon"] {
    max-width: 1.25em !important;
  }

  &[data-type="square"] {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      padding-bottom: 0;
    }
  }

  &[data-type="button"] {
    border-radius: $border-radius;

    &:after {
      height: 34px;
    }
  }

  &[data-type="page"] {
    &:after {
      position: absolute;
      padding-bottom: 100%;
    }
  }
}
</style>
