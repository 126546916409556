<template>
  <transition name="fade">
    <div class="tip-bar" v-if="active">
      <div class="scroll-tip" @click="scrollDown">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'scrollTip',
  data() {
    return {
      active: true
    }
  },
  mounted() {
    const route = this.$route.name
    const viewTimes = this.$ls.get('scrollTip.' + route) || 0
    if (viewTimes > 3) {
      this.$el.remove()
      this.$destroy()
      return
    }
    this.$ls.set('scrollTip.' + route, parseInt(viewTimes) + 1)
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      this.active = window.pageYOffset < 200
    },
    scrollDown() {
      const duration = 1000
      const startingY = window.pageYOffset
      const diff = window.innerHeight * 2 - startingY
      let start = null

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1)

        window.scrollTo(0, startingY + diff * percent)

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .scroll-tip {
    padding: .75em 1.75em;
    padding-left: 1.5em;
    text-align: center;
    border-radius: 100px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    box-shadow: $box-shadow;
    display: inline-block;
    user-select: none;
    cursor: pointer;
    opacity: .95;
    transition: opacity .3s;

    &:hover {
      opacity: 1;
    }
  }

  .tip-bar {
    user-select: none;
    position: fixed;
    bottom: 1rem;
    @include media-breakpoint-down(sm) {
      bottom: 76px;
    }
    left: 0;
    right: 0;
    z-index: 20;
    text-align: center;
  }
</style>
