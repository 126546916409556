<template>
  <h2 class="page-title">
    <slot></slot>

    <div class="right" v-if="$slots.right">
      <slot name="right"></slot>
    </div>
  </h2>
</template>

<script>
export default {
  name: 'pageTitle'
}
</script>

<style scoped>

</style>
