import { forEach, set, sortBy, toString } from 'lodash'

import request from '@/plugins/request'
import { getBookId } from '@/models/book'

export function fetchAlbums({page, size = 24, isWm}) {
  return request.get('/jianxiang/api/1/albums/my/', {
    params: {
      ordering: '-updated_at',
      page,
      size
    }
  }).then(albums => {
    const totalCount = albums.totalCount
    albums = albums.filter(i => isWm ? i.tid.startsWith('wm-') : !i.tid.startsWith('wm-'))
      .map(extendAlbum)
    albums.totalCount = totalCount
    return albums
  })
}

export function fetchWeimengAlbums({page, size}) {
  return fetchAlbums({page, size, isWm: true})
}

export function fetchAlbum({albumId}, silent) {
  return request.get('/jianxiang/api/1/albums/' + albumId + '/', {silent}).then(extendAlbum)
}

export function fetchValidation({albumId}) {
  return request.post(`/jianxiang/api/1/albums/${albumId}/validate/`).then(data => {
    if (data.images) {
      return sortBy(data.images, 'index')
    }
    return sortBy(data, 'index')
  }).then(items => {
    return items.map(item => {
      if (item.reason) {
        item.title = {
          page_size: '页数有点少哦',
          small_image: '图片尺寸偏小',
          image_count: '图片数量不够哦'
        }[item.reason] || item.reason

        if (item.reason === 'small_image') {
          item.message = `图片 ${item.width}×${item.height} 偏小`
          item.suggestion = '返回编辑页面，点击图片右上角红色感叹号查看具体建议'
        }
      }
      return item
    })
  })
}

export function fetchAlbumPages({albumId, index}) {
  let url = `/jianxiang/api/1/albums/${albumId}/pages/`
  index = toString(index)
  if (index) {
    url += '?indexes=' + index
  }
  return request.get(url).then(extendAlbumPages)
}

export function fetchAlbumImages({albumId}) {
  return request.get(`/jianxiang/api/1/album_images/my/?aid=${albumId}&size=240`)
}

export function fetchUnusedImages({albumId}) {
  return request.get(`/jianxiang/api/1/albums/${albumId}/images/`, {
    params: {
      used: false,
      page: 1,
      size: 240
    }
  })
}

export function fetchAvailableTemplates({innerStyle, tid = 'album'}) {
  return request.get(`/jianxiang/api/1/templates/${tid}/pages/?style=${innerStyle}`)
    .then(results => {
      return results.map(item => {
        item.url = 'https://canvas.xinshu.me/generate/' + item.style + '.' + item.name +
          '?format=jpeg&ver=3&size=320'
        return item
      })
    })
}

const caches = {}

export async function fetchActivities({albumId, page = 1}) {
  return request.get(`/jianxiang/api/1/albums/${albumId}/activities/?page=${page}`)
}

export async function fetchPage(page, innerStyle, size) {
  page.name = page.name.toString()
  let url = ''
  url = 'https://canvas.xinshu.me/generate/html/' + innerStyle + '.' + page.name
  if (window.isDev) {
    url = 'http://localhost:4002/generate/html/' + innerStyle + '.' + page.name.split('.').slice(-1)[0]
  }

  const queryParams = {
    dpr: window.devicePixelRatio || 1,
    page: String(page.pageIndex + 1),
    size
  }

  if (window.canUseWebp) {
    queryParams.webp = 1
  }

  const layers = page.layers

  const pics = layers.filter(l => l.type === 'image')
  const texts = layers.filter(l => l.type === 'text')

  pics.forEach((layer, i) => {
    if (!layer.content) {
      return
    }
    set(queryParams, [layer.name, 'content'], layer.content.split('!')[0])
    set(queryParams, [layer.name, 'dx'], layer.x)
    set(queryParams, [layer.name, 'dy'], layer.y)
    set(queryParams, [layer.name, 'dw'], layer.w)
    set(queryParams, [layer.name, 'dh'], layer.h)
    set(queryParams, [layer.name, 'dr'], layer.rotate)
    set(queryParams, [layer.name, 'ds'], layer.scale)
    if (layer.text) {
      set(queryParams, [layer.name, 'text'], layer.text)
    }
  })

  texts.forEach(layer => {
    if (!layer.content) {
      return
    }
    set(queryParams, [layer.name, 'content'], layer.content)
  })

  if (page.title) {
    queryParams.title = page.title
  }

  if (page.subtitle) {
    queryParams.subtitle = page.subtitle
  }

  if (page.meta) {
    forEach(page.meta, (val, key) => {
      queryParams[key] = val
    })
  }

  const fullUrl = url + '?' + JSON.stringify(queryParams)

  let result = caches[fullUrl]

  if (!result) {
    result = await request.post(url, queryParams)
    caches[fullUrl] = result
  }

  const div = document.createElement('div')
  div.innerHTML = result

  layers.forEach(layer => {
    const layerEl = div.querySelector(`[name="${layer.name}"]`)
    if (!layerEl) {
      return
    }
    if (layer.id) {
      layerEl.id = layer.id
    }
    if (layer.index) {
      layerEl.dataset.index = layer.index
    }
  })

  return div.innerHTML
}

export function extendAlbum(album) {
  album.title = album.name
  album.id = album.aid
  album.bookType = 'albums'
  album.bookId = getBookId(album)
  album.pages = album.userPageCount
  album.coverParams = album.cover
  album.updateTime = album.updatedAt
  album.type = album.innerStyle.id
  album.typesetName = album.innerStyle.name
  album.isAlbum = true
  album.auth = album.isOwner ? 'edit' : 'view'
  if (album.forAgent) {
    album.agentOpenid = album.uid
  }
  return album
}

export function extendAlbumPages(pages) {
  return pages.map((page, index) => {
    page.pageIndex = index
    page.layers = page.layers.map((layer, i) => {
      layer.index = [index, i].join('_')
      layer.pageIndex = index
      return layer
    })
    page.layers.filter(l => l.type === 'image').forEach((layer, i) => {
      layer.name = 'pic' + (i > 0 ? i + 1 : '')
    })
    page.layers.filter(l => l.type === 'text').forEach((layer, i) => {
      layer.name = 'text' + (i > 0 ? i + 1 : '')
    })
    return page
  })
}
