<template>
  <div :class="{reverse, disabled}"
       @click="$el.querySelector('#' + elId).click(); $emit('click')"
       class="cell">
    <div class="row align-items-center">
      <div :style="{maxWidth: headWidth}" class="cell-head col-auto" v-if="$slots.head">
        <slot name="head"></slot>
      </div>
      <div class="col cell-body">
        <slot v-if="$slots.default"></slot>
        <slot name="body" v-else-if="$slots.body"></slot>
        <template v-else>{{title}}</template>
      </div>
      <div class="col-auto checkbox-item">
        <div class="checkbox checkbox-primary checkbox-circle">
          <input v-model="iv" :disabled="disabled" :id="elId"
                 :value="val" type="radio" v-if="type === 'radio'">
          <input v-model="iv" :disabled="disabled" :id="elId"
                 type="checkbox" v-if="type === 'checkbox'">
          <label :for="elId" @click.stop></label>
          <fa icon="check"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cell',
  props: {
    value: {
      required: true
    },
    val: [String, Boolean],
    type: {
      type: String,
      default: 'radio'
    },
    group: String,
    title: String,
    disabled: Boolean,
    reverse: Boolean,
    headWidth: String
  },
  computed: {
    iv: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  data() {
    return {
      groupName: this.group || this.$vnode.data.model.expression,
      elId: 'cell_' + Math.random().toString(16).slice(2)
    }
  }
}
</script>

<style lang="scss" scoped>
  .cell {
    & + .cell {
      margin-top: 1em;
    }

    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &.reverse > .row {
      flex-direction: row-reverse;
    }

    &:not(.reverse) {
      .cell-head {
        padding-right: 0;
      }
    }
  }

  //
  // Checkboxes
  // --------------------------------------------------

  $icon-size: 24px;

  .checkbox {
    position: relative;
    height: $icon-size;
    width: $icon-size;
    text-align: center;
    color: #fff;

    label {
      display: block;
      position: absolute;
      margin-bottom: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $input-border-color;
      border-radius: 100px;
      pointer-events: none;
    }

    .fa-check {
      position: relative;
      z-index: 2;
      opacity: 0;
      left: 1px;
      line-height: 24px;
      font-size: 12px;
      pointer-events: none;
    }

    input[type="checkbox"],
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      z-index: 1;
      pointer-events: none;

      &:disabled {
        & + label {
          opacity: 0.65;
          background-color: $hr-border-color;
        }
        & ~ .fa-check {
          opacity: 0;
        }
      }

      &:checked {
        & + label {
          background-color: $primary;
          border-color: $primary;
        }
        & ~ .fa-check {
          opacity: 1;
        }
      }
    }

    &.checkbox-circle label::before {
      border-radius: 100px;
    }

    &.checkbox-inline {
      margin-top: 0;
    }
  }
</style>
