<template>
  <span>
    <img :src="src" :alt="content">
    <span style="display: none;"><slot></slot></span>
  </span>
</template>

<script>
export default {
  name: 'imageText',
  props: {
    family: String,
    color: String
  },
  data() {
    return {
      textColor: '#000'
    }
  },
  mounted() {
    this.textColor = getComputedStyle(this.$el).color
  },
  computed: {
    content() {
      return this.$slots.default[0].text
    },
    src() {
      const query = {
        content: this.content,
        fillStyle: this.color || this.textColor,
        fontFamily: this.family || 'FZQingKeBenYueSongS-R-GB',
        lineHeight: 1,
        format: 'svg',
        fontSize: 16
      }

      return 'https://canvas.xinshu.me/text' + this.serialize(query, true, false)
    }
  }
}
</script>

<style scoped>
  img {
    height: 1em;
  }
</style>
