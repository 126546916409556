<template>
  <div class="divider" :class="{vertical}">
    <span v-if="$slots.default">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'divider',
  props: {
    vertical: Boolean
  }
}
</script>

<style scoped lang="scss">
.divider {
  width: 100%;
  text-align: center;
  color: $text-muted;
  font-size: 12px;
  height: 3em;

  &:after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid $hr-border-color;
    margin-top: -1.5em;
  }

  span {
    padding-left: .75em;
    padding-right: .75em;
    position: relative;
    line-height: 3;
    z-index: 1;
    background-color: #fff;
  }

  &.vertical {
    width: 0;
    height: auto;
    border-left: 1px solid $hr-border-color;

    &:after, span {
      display: none;
    }
  }
}

</style>
