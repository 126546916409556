/**
 * Created by chenzhuokai on 2017/1/9.
 */
import Vue from 'vue'

Vue.directive('maxLine', {
  inserted(el, {value}) {
    if (value && /textarea/i.test(el.tagName)) {
      el.inputHandler = (e) => {
        if (el.value.split('\n').length > value) {
          if (e.keyCode === 13) {
            e.preventDefault()
            return false
          }
        }
      }
      el.addEventListener('keydown', el.inputHandler, false)
    }
  },
  unbind(el) {
    el.removeEventListener('keydown', el.inputHandler)
  }
})
