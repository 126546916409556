import axios from 'axios'
import { get, isObject } from 'lodash'
import { camelCaseObj, snakeCaseObj } from '@/utils/camelcase'
import store from '@/vuex/store'
import $alert from '@/plugins/alert'

axios.interceptors.response.use(
  response => {
    let data = response.data || {}
    if (data.errors) {
      data.errcode = data.errors.code || 0
      data.errmsg = data.errors.message
      delete data.errors
    }

    if ((data.errcode || data.errmsg) && !response.config.fallThrough) {
      const message = getErrorMessage(response)
      if (!response.config.silent) {
        $alert.error(message)
      }
      const error = new Error(message)
      error.errcode = data.errcode
      error.response = response
      if (error.errcode === 21003 && !/^agent/.test(store.state.token)) {
        store.commit('resetAuth')
      }
      return Promise.reject(error)
    }

    data = camelCaseObj(data)

    if (response.config.raw) {
      return data
    }

    const totalCount = data.totalCount ?? data.count
    const timestamp = data.timestamp

    if (data.data) {
      data = data.data
    }

    if (data.results) {
      data = data.results
    }

    if (timestamp) {
      data.timestamp = timestamp
    }

    if (totalCount) {
      data.totalCount = totalCount
    }

    return data
  },
  error => {
    console.error('Request error', error)
    const response = error.response || error
    const msg = getErrorMessage(response)
    if (msg && !response?.config?.silent) {
      $alert.error(msg)
    }
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(config => {
  const isSameOrigin = /^\//.test(config.url)
  if (isSameOrigin && store.state.token) {
    config.headers.Authorization = store.state.token
  }
  if (config.data && !(config.data instanceof FormData) && isSameOrigin) {
    config.data = snakeCaseObj(config.data, !isObject(config.data))
  }
  return config
})

export default axios

export function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  Vue.prototype.$req = axios
  Vue.prototype.$request = axios
}

const messages = {
  401: '您没有登录哦，请登录后再试',
  404: '没有相关的页面或资源哦',
  500: '服务器发生了一些错误，请稍后再试',
  502: '哦哟，服务器发生了一些错误，请稍后再试',
  503: '服务器太忙了，请稍后试试吧',
  504: '服务超时，请稍后试试吧'
}

function getErrorMessage(response) {
  const message = get(response, 'data.errmsg') || get(response, 'data.message') ||
    messages[response.status] || '发生了一些错误，请稍后再试哦'
  const code = get(response, 'data.errcode') || get(response, 'data.code') || response.status ||
    'UNKNOWN'
  return message + '(' + code + ')'
}
