import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

window.dayjs = dayjs

dayjs.locale('zh-cn')
dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/duration'))
dayjs.extend(require('dayjs/plugin/calendar'))
dayjs.extend(require('dayjs/plugin/isBetween'))
dayjs.extend(require('dayjs/plugin/updateLocale'))
dayjs.extend(require('dayjs/plugin/localizedFormat'))

dayjs.updateLocale('zh-cn', {
  calendar: {
    sameDay: '[今天] LT',
    nextDay: '[明天] LT',
    lastDay: '[昨天] LT',
    sameElse: 'YYYY/MM/DD',
    lastWeek(now) {
      if (this.isBefore(now.startOf('week'))) {
        return '上' + this.format('ddd LT')
      }
      return this.format('[本]ddd LT')
    },
    nextWeek(now) {
      if (this.isAfter(now.endOf('week'))) {
        return '下' + this.format('ddd LT')
      }
      return this.format('[本]ddd LT')
    }
  }
})

Vue.filter('moment', (val, format) => {
  if (format === 'calendar') {
    return dayjs(val).calendar()
  }
  return dayjs(val).format(format)
})

export default dayjs

export function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  Vue.prototype.$day = dayjs
}
